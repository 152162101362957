import { h, Fragment } from 'preact';
import { useState, useRef } from 'preact/hooks';
import Joi from 'joi';

import { Checkbox, Field, Input, Radio, RadioGroup, Button, Autocomplete, Heading, Box } from '../components';
import { api } from '../helpers';
import Form from '../unform';

const schema = Joi.object({
    fullName: Joi.string(),
    login: Joi.string().min(3).uppercase(),
    password: Joi.string().min(6).allow(''),
    roles: Joi.number(),
    deleted: Joi.bool(),
    id: Joi.number(),
    disablePassChange: Joi.bool(),
});

export const EditUserForm = ({ user, setUser, updateUsers }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [passChangable, setPassChangable] = useState(false);

    console.log(user);

    const handleSubmit = async (data, { reset }) => {
        data.id = user.id;
        console.log(data);
        setLoading(true);
        setErrors({});
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });

            const resp = await api.patch('users', { json: result });
            console.log(resp);
            reset();
            setUser(null);
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
        updateUsers();
        setLoading(false);
    };

    return (
            user?(
            <Box>
                <Heading size={5}>Редактирование пользователя {user.fullName}</Heading>
                <Form onSubmit={handleSubmit}>
                    <Field horizontal label="ФИО" required>
                        <Field error={errors.fullName}>
                            <Input name="fullName" placeholder="Иванов Иван Иванович" value={user.fullName}></Input>
                        </Field>
                    </Field>
                    <Field horizontal label="Логин" required>
                        <Field error={errors.login}>
                            <Input name="login" placeholder="ABC" value={user.login}/>
                        </Field>
                    </Field>
                    <Field horizontal label="Пароль" required>
                        <Field error={errors.password}>
                            <Input name="password" disabled={passChangable?false:true} value=''/>
                        </Field>
                    </Field>
                    <Field horizontal label="Роль" required>
                        <Field error={errors.roles}>
                            <Input name="roles" type="number" min="0" value={user.roles}/>
                        </Field>
                    </Field>
                    <Field horizontal>
                        <Checkbox name="deleted" value={user.deletedAt}>
                            Удален
                        </Checkbox>
                        <Checkbox name="disablePassChange" onChange={()=>setPassChangable(!passChangable)} value={!passChangable}>
                            Не менять пароль
                        </Checkbox>
                    </Field>
                    <Field horizontal>
                        <Field error={errors.userExists}>
                            <Button fullwidth color="primary" loading={loading}>
                                Редактировать
                            </Button>
                        </Field>
                    </Field>
                </Form>
            </Box>
        ):'' 
    );
};


