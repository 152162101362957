import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Column = ({
    span,
    offset,
    narrow,
    mobile = {},
    tablet = {},
    desktop = {},
    widescreen = {},
    fullhd = {},
    touch = {},
    ...props
}) => (
    <Element
        className={clsx('column', {
            [`is-${span}`]: span,
            [`is-${touch.span}-mobile`]: touch.span,
            [`is-${mobile.span}-mobile`]: mobile.span,
            [`is-${tablet.span}-tablet`]: tablet.span,
            [`is-${desktop.span}-desktop`]: desktop.span,
            [`is-${widescreen.span}-widescreen`]: widescreen.span,
            [`is-${fullhd.span}-fullhd`]: fullhd.span,
            [`is-offset-${touch.offset}-mobile`]: touch.offset,
            [`is-offset-${mobile.offset}-mobile`]: mobile.offset,
            [`is-offset-${tablet.offset}-tablet`]: tablet.offset,
            [`is-offset-${desktop.offset}-desktop`]: desktop.offset,
            [`is-offset-${widescreen.offset}-widescreen`]: widescreen.offset,
            [`is-offset-${fullhd.offset}-fullhd`]: fullhd.offset,
            [`is-offset-${offset}`]: offset,
            'is-narrow': narrow,
            'is-narrow-touch': touch.narrow,
            'is-narrow-mobile': mobile.narrow,
            'is-narrow-tablet': tablet.narrow,
            'is-narrow-desktop': desktop.narrow,
            'is-narrow-widescreen': widescreen.narrow,
            'is-narrow-fullhd': fullhd.narrow,
        })}
        {...props}
    />
);
