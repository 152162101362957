import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    person: Joi.string(),
    date: Joi.date(),
    q1_1: Joi.string(),
    q1_2: Joi.bool(),
    q1_3: Joi.bool(),
    q1_4: Joi.string().allow(''),
    q2_2: Joi.bool(),
    q2_3: Joi.string().allow(''),
    q3_1: Joi.string(),
    q3_2: Joi.string().allow(''),
    q3_3: Joi.bool(),
    q3_4: Joi.bool(),
    q3_5: Joi.bool(),
    q4_1: Joi.string(),
    q4_2: Joi.string().allow(''),
    q5_1: Joi.string(),
    q5_2: Joi.string().allow(''),
    q6: Joi.array(),
    q7: Joi.string(),
    q8: Joi.string(),
});

export const GynecologistForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [expandedFields, setExpandedFields] = useState([]);
    const [hints, setHints] = useState(null);

    initialData.date = formatDateToYMD(initialData.date);

    useEffect(() => {
        api.get(`checkups/${checkupId}/hints`)
            .json()
            .then(res => setHints(res));
    }, [checkupId]);

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/17`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    const searchDisease = async str => {
        const items = await api.get('diseases', { searchParams: { query: str } }).json();
        return items;
    };

    const expandField = (name, expandedVal) => val => {
        if (val === expandedVal) {
            setExpandedFields(prev => [...prev, name]);
        } else {
            setExpandedFields(prev => prev.filter(field => name !== field));
        }
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field horizontal label="Жалобы">
                <Field>
                    <RadioGroup name="q1_1" onChange={expandField('q1_1', '2')}>
                        <Radio value="1"> нет </Radio>
                        <Radio value="2"> есть </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q1_1') })}>
                    <Checkbox name="q1_2"> болезненные менструации</Checkbox>
                    <Checkbox name="q1_3"> не регулярные менструации</Checkbox>
                    <Input type="text" name="q1_4"></Input>
                </Field>
            </Field>
            <Field horizontal label="Анамнез">
                <Field>
                    <Checkbox name="q2_2"> Менструации регулярные </Checkbox>
                    <Input name="q2_3" placeholder="ДПМ"/>
                </Field>
            </Field>
            <Field horizontal label="Половая жизнь">
                <Field>
                    <RadioGroup name="q3_1" onChange={expandField('q3_1', '2')}>
                        <Radio value="1"> нет </Radio>
                        <Radio value="2"> есть </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q3_1') })}>
                    <Input type="number" name="q3_2" placeholder="с лет"></Input>
                    <Field label="Контрацепция">
                        <Checkbox name="q3_3"> барьерная</Checkbox>
                        <Checkbox name="q3_4"> КОК</Checkbox>
                        <Checkbox name="q3_5"> ППА</Checkbox>
                    </Field>
                </Field>
            </Field>
            <Field horizontal label="Молочные железы">
                <Field>
                    <RadioGroup name="q4_1" onChange={expandField('q4_1', '2')}>
                        <Radio value="1"> норма </Radio>
                        <Radio value="2"> нет </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q4_1') })}>
                    <Input name="q4_2"/>
                </Field>
            </Field>
            <Field horizontal label="Status genitalis">
                <Field>
                    <RadioGroup name="q5_1" onChange={expandField('q5_1', '2')}>
                        <Radio value="1"> норма </Radio>
                        <Radio value="2"> нет </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q5_1') })}>
                    <Input name="q5_2"/>
                </Field>
            </Field>
            <Field horizontal label="Диагноз (шифр по МКБ)">
                <Field
                    caption={
                        hints &&
                        hints.diseases.length > 0 &&
                        'Рекомендуются: ' + hints.diseases.map(d => d.code).join('; ')
                    }
                >
                    <TagsInput
                        name="q6"
                        placeholder="МКБ-10"
                        onSearch={searchDisease}
                        itemToTag={item => item.code}
                        itemToLabel={item => `${item.code} ${item.name}`}
                        itemToId={item => item.id}
                    />
                </Field>
            </Field>
            <Field horizontal label="Цитология">
                <Field>
                    <RadioGroup name="q7">
                        <Radio value="1"> Взята цитология с шейки матки и цервикального канала </Radio>
                        <Radio value="2"> Не подлежит </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Рекомендация">
                <Field>
                    <RadioGroup name="q8">
                        <Radio value="1"> КОК </Radio>
                        <Radio value="2"> Гигиена </Radio>
                        <Radio value="3"> 2-ой голландский метод </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
};

GynecologistForm.defaultProps = {
    initialData: {  
        date: todayDate(),
        q1_1: '1',
        q1_2: false,
        q1_3: false,
        q3_1: '1',
        q4_1: '1',
        q5_1: '1',
        q6: [],
        q7: '1',
        q8: '1',
    },
};
