import { h } from 'preact';

import { Container, Box, Row, Column } from '../components';

function Home() {
    return (
        <Container>
            <Row centered>
                <Column span={6}>
                    <Box>Главная страница</Box>
                </Column>
            </Row>
        </Container>
    );
}

export default Home;
