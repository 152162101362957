import { h, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useLocation } from 'wouter-preact';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Container, Box, Table, Row, Column, Title, Field, Button, Input } from '../components';
import { CheckIcon, HourglassIcon, XIcon } from '../icons';


export default CheckupsTable = ({checkups = [], isCentralHealth}) => {

    const [, setLocation] = useLocation();

    const onServiceClick = s => () => {
        console.log('Service:', s);
        setLocation(`/measurement/${s.id}`);
    };

    return <>
    <Box>
        {isCentralHealth?<Title>Центр здоровья</Title>:<Title>Услуги</Title>}
        {
            checkups.length !== 0? 
                <>
            <Table bordered striped fullwidth hoverable narrow>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{isCentralHealth?'Тип обследования':'Услуга'}</th>
                    <th>Добавлено</th>
                    <th>Обновлено</th>
                    <th>Статус</th>
                </tr>
            </thead>
            <tbody>
                {checkups.map((service, index) => {
                    const isEditable = service.fulfilledAt;

                    return (
                        <tr
                            key={service.id}
                            className={
                                isEditable ? 'is-clickable has-background-success-light' : ''
                            }
                            onClick={isEditable ? onServiceClick(service) : undefined}
                        >
                            <td className="is-vcentered has-text-centered">{index + 1}</td>
                            <td className="is-vcentered">{service.name}</td>
                            <td className="is-vcentered has-text-centered">
                                {dayjs(service.createdAt).format('DD.MM.YYYY HH:mm')}
                            </td>
                            <td className="is-vcentered has-text-centered">
                                {service.updatedAt && (
                                    <>
                                        {dayjs(service.updatedAt).format('DD.MM.YYYY HH:mm')}
                                        <div className="is-size-7">{service.updatedBy}</div>
                                    </>
                                )}
                            </td>
                            <td
                                className={clsx({
                                    'has-text-success': service.fulfilledAt !== null,
                                    'has-text-danger': service.rejectedAt !== null,
                                    'has-text-centered': true,
                                    'is-vcentered': true,
                                })}
                            >
                                {service.fulfilledAt ? (
                                    <>
                                        <CheckIcon />{' '}
                                        {dayjs(service.fulfilledAt).format('DD.MM.YYYY HH:mm')}
                                    </>
                                ) : service.rejectedAt ? (
                                    <>
                                        <XIcon />{' '}
                                        {dayjs(service.rejectedAt).format('DD.MM.YYYY HH:mm')}
                                    </>
                                ) : (
                                    <HourglassIcon />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
                </>  
            :'Ничего не пройдено'
        }
    </Box>
    </>

}