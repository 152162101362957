import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';
import { useNavbarContext } from './NavbarContext';

export const NavbarBurger = props => {
    const { isActive, setActive } = useNavbarContext();

    return (
        <Element
            className={clsx('navbar-burger', isActive && 'is-active')}
            onClick={() => setActive(prev => !prev)}
            {...props}
        >
            <span />
            <span />
            <span />
        </Element>
    );
};
