import { h } from 'preact';
import { Switch, Route, Router } from 'wouter-preact';

import { Navbar } from './components';
import { PrivateRoute } from './PrivateRoute';

import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Help from './pages/Help';
import Settings from './pages/Settings';
import Services from './pages/Services';
import Patients from './pages/Patients';
import Users from './pages/Users';
import Login from './pages/Login';
import NewPatient from './pages/NewPatient';

import Over65 from './pages/Over65';
import Under65 from './pages/Under65';
import Survey from './pages/Survey';

import Therapist from './pages/Therapist';
import Gynecologist from './pages/Gynecologist';

import Anthropometry from './pages/Anthropometry';
import Ophthalmologist from './pages/Ophthalmologist';
import ECG from './pages/ECG';
import Xray from './pages/Xray';
import Stool from './pages/Stool';
import Blood from './pages/Blood';
import EditMeasurement from './pages/EditMeasurement';
import Dentist from './pages/healthСenter/Dentist';
import PatientProfile from './pages/PatientProfile';

import { useAuth } from './context/auth-context';
import { hasRole } from './helpers';
import { Role } from './Role';
import GeneralInfoAnamnesis from './pages/healthСenter/GeneralInfoAnamnesis';
import SurveyResults from './pages/healthСenter/SurveyResults';

export const App = () => {
    const { user, logout } = useAuth();

    return (
        <Router>
            {user && (
                <Navbar>
                    <Navbar.Brand>
                        <Navbar.Item href="/">
                            <a className="navbar-item">
                                <img src="/icon.svg" width="32" height="32" alt="Кубик" />
                            </a>
                        </Navbar.Item>
                        <Navbar.Burger />
                    </Navbar.Brand>
                    <Navbar.Menu>
                        <Navbar.Container>
                            <Navbar.Item hoverable>
                                <Navbar.Link>ЦЗ</Navbar.Link>
                                <Navbar.Dropdown>
                                    <Navbar.Item href="/new-patient">Общие сведения</Navbar.Item>
                                    <Navbar.Item href="/survey-results">Результаты обследования</Navbar.Item>
                                    {/*<Navbar.Item href="/general-info">Общие сведения</Navbar.Item>*/}
                                    <Navbar.Item href="/general-info-anamnesis">Общие данные анамнеза</Navbar.Item>
                                    <Navbar.Item href="/dentist">Карта гигиениста</Navbar.Item>
                                    {/*<Navbar.Item href="/blood">Забор крови</Navbar.Item>
                                    <Navbar.Item href="/ophthalmologist">Офтальмолог</Navbar.Item> 
                                    {/*<Navbar.Item href="/final-health-status">Итоговая оценка состояния здоровья</Navbar.Item>*/}   
                                </Navbar.Dropdown>    
                            </Navbar.Item>
                            <Navbar.Item hoverable>
                                <Navbar.Link>Анкета</Navbar.Link>
                                <Navbar.Dropdown>
                                    <Navbar.Item href="/under65">До 65</Navbar.Item>
                                    <Navbar.Item href="/over65">После 65</Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                            <Navbar.Item hoverable>
                                <Navbar.Link>Анализы</Navbar.Link>
                                <Navbar.Dropdown>
                                    <Navbar.Item href="/anthropometry">Антропометрия</Navbar.Item>
                                    <Navbar.Item href="/ecg">ЭКГ</Navbar.Item>
                                    <Navbar.Item href="/xray">ФОГ</Navbar.Item>
                                    <Navbar.Item href="/stool">Кал на кровь</Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                            <Navbar.Item hoverable>
                                <Navbar.Link>Осмотр</Navbar.Link>
                                <Navbar.Dropdown>
                                    <Navbar.Item href="/therapist">Терапевт</Navbar.Item>
                                    <Navbar.Item href="/gynecologist">Гинеколог</Navbar.Item>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                            <Navbar.Item href="/services">Услуги</Navbar.Item>
                            <Navbar.Item href="/patients">Пациенты</Navbar.Item>
                            {hasRole(user, Role.Admin) && (
                                <Navbar.Item hoverable>
                                    <Navbar.Link>Админ</Navbar.Link>
                                    <Navbar.Dropdown right>
                                        <Navbar.Item href="/users">Пользователи</Navbar.Item>
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                            )}
                        </Navbar.Container>
                        <Navbar.Container align="end">
                            <Navbar.Item href="/login" onClick={logout}>
                                Выход
                            </Navbar.Item>
                        </Navbar.Container>
                    </Navbar.Menu>
                </Navbar>
            )}
            <Switch>
                <PrivateRoute path="/" component={Home} />
                <PrivateRoute path="/new-patient" component={NewPatient} />
                <PrivateRoute path="/help" component={Help} />
                <PrivateRoute path="/settings" component={Settings} />
                <Route path="/login" component={Login} />
                <PrivateRoute path="/over65" component={Over65} />
                <PrivateRoute path="/under65" component={Under65} />
                <PrivateRoute path="/survey" component={Survey} />
                <PrivateRoute path="/measurement/:id">
                    {params => <EditMeasurement measurementId={params.id} />}
                </PrivateRoute>
                <PrivateRoute path="/ecg" component={ECG} />
                <PrivateRoute path="/xray" component={Xray} />
                <PrivateRoute path="/stool" component={Stool} />
                <PrivateRoute path="/blood" component={Blood} />
                <PrivateRoute path="/therapist" component={Therapist} />
                <PrivateRoute path="/gynecologist" component={Gynecologist} />
                <PrivateRoute path="/ophthalmologist" component={Ophthalmologist}/>
                <PrivateRoute path="/anthropometry" component={Anthropometry} />
                <PrivateRoute path="/services" component={Services} />
                <PrivateRoute path="/patients" component={Patients} />
                <PrivateRoute path="/users" component={Users} />
                <PrivateRoute path="/general-info-anamnesis"component={GeneralInfoAnamnesis}/>
                <PrivateRoute path="/survey-results"component={SurveyResults}/>
                <PrivateRoute path="/dentist" component={Dentist}/>
                <PrivateRoute path="/patient/profile/:id">
                    {params => <PatientProfile patientId={params.id}/>}
                </PrivateRoute>
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};
