import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';
import { HeroHead } from './HeroHead';
import { HeroBody } from './HeroBody';
import { HeroFoot } from './HeroFoot';

export const Hero = ({ color, gradient, size, hasNavbar, ...props }) => (
    <Element
        tag="section"
        className={clsx('hero', {
            [`is-${color}`]: color,
            [`is-${size}`]: size && !hasNavbar,
            'is-bold': gradient,
            'is-fullheight-with-navbar': hasNavbar,
        })}
        {...props}
    />
);

Hero.Head = HeroHead;
Hero.Body = HeroBody;
Hero.Foot = HeroFoot;
