import { h, Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title, Field, Input, Button } from '../../components';
import { api } from '../../helpers';
import { GeneralInfoAnamnesisForm } from '../../forms/GeneralInfoAnamnesis';
import { PatientsQueue } from '../../components/PatientsQueue';
import { FinalHealthStatusForm } from '../../forms/FinalHealthResult';
import Form from '../../unform';

const GeneralInfoAnamnesis = () =>
{
    const [patient, setPatient] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [patientPlan, setPatientPlan] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [direction, setDirection] = useState('');

    useEffect(() => {
        api.get('users')
            .json()
            .then(list => {
                setDoctors(list);
            });
    }, []);

    const onPatientSelect = async pat => {
        setPatient(pat);
          
        const direction = await api.get(`checkups/${pat.checkupId}/direction`).json();
        setDirection(direction.directed || '');

        const services = await api.get(`checkups/${pat.checkupId}/services`).json();
        setChecklist(services);

        const plan = services.filter(s => s.typeId === 1 && s.settledById === null);
        setPatientPlan(plan);
    };

    const changeDirection = async (data) => {
        try {
            console.log(data);
            const resp = await api.post(`checkups/${patient.checkupId}/direction`, {json: data});
            console.log(resp);
        }
        catch(e) {
            console.log(e);
        }
    }


    return (
        <Container>
            <Row centered>
                <Column span={8}>
                    {patient ? (
                        <>
                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                            </Title>
                            <Box>
                                <Form onSubmit={changeDirection} >
                                    <Field horizontal label="Направлен">
                                        <Input name="directed" value={direction}></Input>
                                        <Button color="info">Направить</Button>
                                    </Field>
                                </Form>
                            </Box>
                            <Box>
                                <Title>Общие данные анамнеза</Title>
                                <GeneralInfoAnamnesisForm checkupId={patient.checkupId} doctors={doctors}/>
                            </Box>
                            <Box>
                                <Title>Итоговая оценка состояния здоровья</Title>
                                <FinalHealthStatusForm checkupId={patient.checkupId} doctors={doctors}></FinalHealthStatusForm>
                            </Box>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <PatientsQueue serviceId={100} onPatientSelect={onPatientSelect} isCentralHealth/>
                </Column>
            </Row>
        </Container>
    );
}

export default GeneralInfoAnamnesis;