import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Table = ({ size, striped, bordered, hoverable, narrow, fullwidth, ...props }) => (
    <Element
        tag="table"
        className={clsx('table', {
            'is-narrow': narrow,
            'is-fullwidth': fullwidth,
            'is-bordered': bordered,
            'is-striped': striped,
            'is-hoverable': hoverable,
        })}
        {...props}
    />
);
