import { h, cloneElement, toChildArray } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Field = ({
    label,
    caption,
    disabled,
    error,
    expanded,
    horizontal,
    children,
    className,
    required,
    ...props
}) => {
    const fieldLabel = label && <label className={clsx('label', { required })}>{label}</label>;

    const fieldInner = toChildArray(children).map((child, index) =>
        cloneElement(child, {
            key: index,
            disabled: child.props.disabled || disabled,
        })
    );

    if (horizontal) {
        return (
            <Element className="field is-horizontal" {...props}>
                <div className="field-label is-normal">{fieldLabel}</div>
                <div className="field-body">{fieldInner}</div>
            </Element>
        );
    }

    return (
        <Element className={clsx('field', className)} {...props}>
            {fieldLabel}
            <div className={clsx('control', { 'is-expanded': expanded })}>{fieldInner}</div>
            {(error || caption) && <p className={clsx('help', { 'is-danger': error })}>{error || caption}</p>}
        </Element>
    );
};
