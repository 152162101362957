import { h, toChildArray } from 'preact';
import { Link, useRoute } from 'wouter-preact';
import clsx from 'clsx';

import { Element } from './Element';
import { NavbarDropdown } from './NavbarDropdown';
import { useNavbarContext } from './NavbarContext';

export const NavbarItem = ({ hoverable, ...props }) => {
    const { setActive } = useNavbarContext();
    const dropdown = toChildArray(props.children).find(child => child.type === NavbarDropdown);
    const as = dropdown ? 'div' : props.href ? Link : 'a';
    const [isActive] = props.href ? useRoute(props.href) : [false];

    const hideMenu = () => setActive(false);

    return (
        <Element
            tag={as}
            className={clsx('navbar-item', {
                'is-active': isActive,
                'has-dropdown': dropdown,
                'is-hoverable': hoverable,
            })}
            onClick={props.href ? hideMenu : undefined}
            {...props}
        />
    );
};
