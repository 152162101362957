import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { CreateUserForm } from '../forms/CreateUser';
import { Container, Box, Table, Row, Column, Title, Button } from '../components';
import { api, formatDateToYMD } from '../helpers';
import { getRoleName } from '../Role';
import { EditUserForm } from '../forms/EditUser';

function Users() {
    const [users, setUsers] = useState([]);
    const [userEdit, setUserEdit] = useState(null);
    const [reload, doReload] = useState(null);

    const updateUsers = () => {
        api.get('users')
            .json()
            .then(list => {
                console.log(list);
                setUsers(list);
            });
    };

    useEffect(() => updateUsers(), []);

    return (
        <Container>
            <Row>
                <Column span={7}>
                    <Box>
                        <Table bordered striped fullwidth>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ФИО</th>
                                    <th>Логин</th>
                                    <th>Роль</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((u, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td style={u.deletedAt?'color:red':''}>{u.fullName + (u.deletedAt !== null?`(${dayjs(u.deletedAt).format("DD.MM.YYYY")})`:'')}</td>
                                        <td>{u.login}</td>
                                        <td>{getRoleName(u.roles)}</td>
                                        <td><Button onClick={()=> setUserEdit(u)}>Ред.</Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Box>
                </Column>
                <Column span={5}>
                    <CreateUserForm updateUsers={updateUsers} />
                    <EditUserForm user={userEdit} setUser={setUserEdit} updateUsers={updateUsers}/>
                </Column>
            </Row>
        </Container>
    );
}

export default Users;
