import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title, Heading, Table, Field } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { TherapistForm } from '../forms/Therapist';
import { CheckIcon, XIcon } from '../icons';
import { api } from '../helpers';

const Therapist = () => {
    const [patient, setPatient] = useState(null);
    const [patientPlan, setPatientPlan] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const onPatientSelect = async pat => {
        setPatient(pat);

        const services = await api.get(`checkups/${pat.checkupId}/services`).json();
        setChecklist(services);

        const plan = services.filter(s => s.typeId === 1 && s.settledById === null);
        setPatientPlan(plan);
    };

    useEffect(() => {
        api.get('users')
            .json()
            .then(list => {
                setDoctors(list);
            });
    }, []);

    return (
        <Container>
            <Row centered>
                <Column span={8}>
                    {patient ? (
                        <>
                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                            </Title>
                            <Box>
                                <TherapistForm checkupId={patient.checkupId} doctors={doctors} />
                            </Box>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <Box>
                        <Heading size={5}>Бегунок</Heading>
                        <Table fullwidth narrow>
                            <tbody>
                                {checklist.length > 0 ? (
                                    checklist.map(item => (
                                        <tr
                                            key={item.id}
                                            className={
                                                item.fulfilledAt === null
                                                    ? 'has-text-danger'
                                                    : 'has-text-success has-text-weight-medium'
                                            }
                                        >
                                            <td>{item.fulfilledAt === null ? <XIcon /> : <CheckIcon />}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={2}>Список пуст</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Box>
                    <PatientsQueue serviceId={14} onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
};

export default Therapist;
