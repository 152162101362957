import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Heading = ({ tag = 'p', size, subtitle, spaced, ...props }) => (
    <Element
        tag={tag}
        className={clsx({
            title: !subtitle,
            subtitle,
            [`is-${size}`]: size,
            'is-spaced': spaced && !subtitle,
        })}
        {...props}
    />
);
