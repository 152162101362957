import { h, Fragment, toChildArray } from 'preact';
import { useState } from 'preact/hooks';

import { Element } from './Element';

export const PanelTabs = ({ active, children, onTabChange, ...props }) => {
    const [activeKey, setActiveKey] = useState(0);

    const switchToTab = index => {
        setActiveKey(index);
        onTabChange && onTabChange(index);
    };

    return (
        <>
            <Element className="panel-tabs" {...props}>
                {toChildArray(children).map((child, index) => {
                    const isActive = activeKey === index;
                    return (
                        <a key={index} className={isActive ? 'is-active' : ''} onClick={() => switchToTab(index)}>
                            {child.props.title}
                        </a>
                    );
                })}
            </Element>
            {toChildArray(children).map((child, index) => {
                const isActive = activeKey === index;
                return isActive ? child.props.children : null;
            })}
        </>
    );
};

PanelTabs.Item = ({ title, children }) => null;
