import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Checkbox, Radio, Table, RadioGroup, Button, TagsInput } from '../components';
import { api } from '../helpers';
import Form from '../unform';

export const Over65Form = ({ checkupId, initialData }) => {
    const [saved, setSaved] = useState(false);

    const handleSubmit = async (data, { reset }) => {
        const q14_20 = [data.q14, data.q15, data.q16, data.q17, data.q18, data.q19, data.q20].filter(Boolean);
        const sum = q14_20.length;

        try {
            const resp = await api.post(`measurements/${checkupId}/2`, { json: { ...data, sum } }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);
        }
    };

    const searchDisease = async str => {
        const items = await api.get('diseases', { searchParams: { query: str } }).json();
        return items;
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form initialData={initialData} onSubmit={handleSubmit}>
            <Table bordered>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Вопрос</th>
                        <th>Ответ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td colSpan="2">Есть ли у Вас следующие хронические заболевания (состояния):</td>
                    </tr>
                    <tr>
                        <td>1.1</td>
                        <td>гипертоническая болезнь (повышенное артериальное давление)?</td>
                        <td>
                            <Checkbox name="q1_1_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения давления?</td>
                        <td>
                            <Checkbox name="q1_1_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.2</td>
                        <td>сахарный диабет или повышенный уровень глюкозы (сахара) в крови?</td>
                        <td>
                            <Checkbox name="q1_2_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения уровня сахара?</td>
                        <td>
                            <Checkbox name="q1_2_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.3</td>
                        <td>злокачественные новообразования?</td>
                        <td>
                            <Checkbox name="q1_3_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то какое?</td>
                        <td>
                            <TagsInput
                                name="q1_3_2"
                                placeholder="МКБ-10"
                                onSearch={searchDisease}
                                itemToTag={item => item.code}
                                itemToLabel={item => `${item.code} ${item.name}`}
                                itemToId={item => item.id}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>1.4</td>
                        <td>повышенный уровень холестерина?</td>
                        <td>
                            <Checkbox name="q1_4_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения уровня холестерина?</td>
                        <td>
                            <Checkbox name="q1_4_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.5</td>
                        <td>перенесённый инфаркт миокарда?</td>
                        <td>
                            <Checkbox name="q1_5"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.6</td>
                        <td>перенесённый инсульт?</td>
                        <td>
                            <Checkbox name="q1_6"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.7</td>
                        <td>хронический бронхит или бронхиальная астма?</td>
                        <td>
                            <Checkbox name="q1_7"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>
                            Возникает ли у Вас, когда поднимаетесь по лестнице, идете в гору или спешите, или при выходе
                            из теплого помещения на холодный воздух, боль или ощущение давления, жжения, тяжести или
                            явного дискомфорта за грудиной или в левой половине грудной клетки, с распространением в
                            левую руку?
                        </td>
                        <td>
                            <Checkbox name="q2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>
                            Если «Да», то проходит ли эта боль в покое через 10-20 мин или через 2-5 мин после приёма
                            нитроглицерина?
                        </td>
                        <td>
                            <Checkbox name="q3"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>
                            Возникала ли у Вас резкая слабость в одной руке и/или ноге так, что Вы не могли взять или
                            удержать предмет, встать со стула, пройтись по комнате?
                        </td>
                        <td>
                            <Checkbox name="q4"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>
                            Возникало ли у Вас внезапное без понятных причин кратковременное онемение в одной руке, ноге
                            или половине лица, губы или языка?
                        </td>
                        <td>
                            <Checkbox name="q5"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Возникала ли у Вас когда-либо внезапно кратковременная потеря зрения на один глаз?</td>
                        <td>
                            <Checkbox name="q6"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Бывают ли у Вас отёки на ногах к концу дня?</td>
                        <td>
                            <Checkbox name="q7"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Курите ли Вы? (курение одной и более сигарет в день)</td>
                        <td>
                            <Checkbox name="q8"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>
                            Были ли у Вас переломы при падении с высоты своего роста, при ходьбе по ровной поверхности
                            или перелом без видимой причины, в т.ч. перелом позвонка?
                        </td>
                        <td>
                            <Checkbox name="q9"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Считаете ли Вы, что Ваш рост заметно снизился за последние годы?</td>
                        <td>
                            <Checkbox name="q10"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>
                            Присутствуют ли в Вашем ежедневном рационе 2 и более порций фруктов и овощей (1 порция = 200
                            гр. овощей или = 1 фрукт среднего размера)
                        </td>
                        <td>
                            <Checkbox name="q11"> Нет</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>
                            Употребляете ли Вы белковую пищу (мясо, рыбу, бобовые, молочные продукты) 3 раза или более в
                            неделю?
                        </td>
                        <td>
                            <Checkbox name="q12"> Нет</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>
                            Тратите ли Вы ежедневно на ходьбу, утреннюю гимнастику и другие физические упраженения 30
                            минут и более?
                        </td>
                        <td>
                            <Checkbox name="q13"> Нет</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Были ли у Вас случаи падений за последний год</td>
                        <td>
                            <Checkbox name="q14"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Испытываете ли Вы существенные ограничения в повседневной жизни из-за снижения зрения?</td>
                        <td>
                            <Checkbox name="q15"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>Испытываете ли Вы существенные ограничения в повседневной жизни из-за снижения слуха?</td>
                        <td>
                            <Checkbox name="q16"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Чувствуете ли Вы себя подавленным, грустным или встревоженным в последнее время?</td>
                        <td>
                            <Checkbox name="q17"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>Страдаете ли Вы недержанием мочи?</td>
                        <td>
                            <Checkbox name="q18"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>
                            Испытываете ли Вы затруднения при перемещении по дому, улице (ходьба на 100 м), подъём на 1
                            лестничный пролёт?
                        </td>
                        <td>
                            <Checkbox name="q19"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>Есть ли у Вас проблемы с памятью, пониманием, ориентацией или способностью планировать?</td>
                        <td>
                            <Checkbox name="q20"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>Считаете ли Вы, что заметно похудели за последнее время (не менее 5 кг за полгода)?</td>
                        <td>
                            <Checkbox name="q21"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>
                            Если Вы похудели, считаете ли Вы, что это связано со специальным соблюдением диеты или
                            увеличением физической активности?
                        </td>
                        <td>
                            <Checkbox name="q22"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>Если Вы похудели, считаете ли Вы, что это связано со снижением аппетита?</td>
                        <td>
                            <Checkbox name="q23"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>Сколько лекарственных препаратов Вы принимаете ежедневно или несколько раз в неделю?</td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            <RadioGroup name="q24">
                                <Radio value="lt5"> До 5</Radio>
                                <Radio value="ge5"> 5 и более</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Button fullwidth color="primary">
                Сохранить
            </Button>
        </Form>
    );
};

Over65Form.defaultProps = {
    initialData: { q24: 'lt5', q1_3_2: [] },
};
