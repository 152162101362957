import { h, toChildArray, cloneElement } from 'preact';
import { useEffect } from 'preact/hooks';

import { useField } from '../unform';
import { useCurrentState } from '../hooks';

export const RadioGroup = ({ name, children, disabled, onChange }) => {
    const { fieldName, registerField, defaultValue } = useField(name);
    const [state, setState, stateRef] = useCurrentState(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: stateRef,
            getValue: () => stateRef.current,
            setValue: (refs, value) => {
                setState(value);
            },
            clearValue: () => {
                setState('');
            },
        });
    }, [stateRef, fieldName, registerField]);

    const handleChange = val => {
        setState(val);
        onChange && onChange(val);
    };

    return toChildArray(children).map(child =>
        cloneElement(child, {
            checked: state === child.props.value,
            disabled: disabled || child.props.disabled,
            onChange: handleChange,
        })
    );
};
