import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';

import { useField } from '../unform';
import { Element } from './Element';

export const Select = ({ name, placeholder, children, disabled, ...props }) => {
    const selectRef = useRef();
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef,
            getValue: ref => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value;
            },
            clearValue: ref => {
                ref.current.value = '';
            },
        });
    }, [fieldName, registerField]);

    return (
        <Element className="select" {...props}>
            <Element tag="select" domRef={selectRef} defaultValue={defaultValue} disabled={disabled}>
                {children}
            </Element>
        </Element>
    );
};
