import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import { FormProvider } from './FormProvider';
import { FormContext } from './context';

const Form = ({ initialData = {}, children, onSubmit, ...rest }, formRef) => {
    return (
        <FormProvider ref={formRef} initialData={initialData} onSubmit={onSubmit}>
            <FormContext.Consumer>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} {...rest}>
                        {children}
                    </form>
                )}
            </FormContext.Consumer>
        </FormProvider>
    );
};

export default forwardRef(Form);
