import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const NavbarDropdown = ({ boxed, right, ...props }) => (
    <Element
        tag="span"
        className={clsx('navbar-dropdown', {
            'is-boxed': boxed,
            'is-right': right,
        })}
        {...props}
    />
);
