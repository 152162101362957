// Значения из cubic-server/src/Role.js
export const Role = {
    Admin: 1,
};

export function getRoleName(role) {
    const names = [];
    for (let [k, v] of Object.entries(Role)) {
        if (role & v) names.push(k);
    }
    return names;
}
