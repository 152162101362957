import { h } from 'preact';
import { Route, Redirect } from 'wouter-preact';

import { useAuth } from './context/auth-context';

export const PrivateRoute = ({ path, component, children }) => {
    const { user } = useAuth();

    return (
        <Route path={path}>
            {params => {
                if (!user) return <Redirect to={`/login?returnTo=${path}`} />;
                if (component) return h(component, { params });
                return typeof children === 'function' ? children(params) : children;
            }}
        </Route>
    );
};
