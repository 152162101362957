import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Element } from './Element';

import { NavbarBrand } from './NavbarBrand';
import { NavbarBurger } from './NavbarBurger';
import { NavbarContainer } from './NavbarContainer';
import { NavbarContext } from './NavbarContext';
import { NavbarDivider } from './NavbarDivider';
import { NavbarDropdown } from './NavbarDropdown';
import { NavbarItem } from './NavbarItem';
import { NavbarLink } from './NavbarLink';
import { NavbarMenu } from './NavbarMenu';

export const Navbar = ({ isActive = false, ...props }) => {
    const [active, setActive] = useState(isActive);

    const value = {
        isActive: active,
        setActive,
    };

    return (
        <NavbarContext.Provider value={value}>
            <Element tag="nav" className="navbar" {...props} />
        </NavbarContext.Provider>
    );
};

Navbar.Brand = NavbarBrand;
Navbar.Burger = NavbarBurger;
Navbar.Container = NavbarContainer;
Navbar.Divider = NavbarDivider;
Navbar.Dropdown = NavbarDropdown;
Navbar.Item = NavbarItem;
Navbar.Link = NavbarLink;
Navbar.Menu = NavbarMenu;
