import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { GynecologistForm } from '../forms/Gynecologist';
import { api } from '../helpers';

const Gynecologist = () => {
    const [patient, setPatient] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [patientPlan, setPatientPlan] = useState([]);
    const [checklist, setChecklist] = useState([]);

    useEffect(() => {
        api.get('users')
            .json()
            .then(list => {
                setDoctors(list);
            });
    }, []);

    const onPatientSelect = async pat => {
        setPatient(pat);
          
        const services = await api.get(`checkups/${pat.checkupId}/services`).json();
        setChecklist(services);

        const plan = services.filter(s => s.typeId === 1 && s.settledById === null);
        setPatientPlan(plan);
    };

    return (
        <Container>
            <Row centered>
                <Column span={8}>
                    {patient ? (
                        <>
                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                            </Title>
                            <Box>
                                <GynecologistForm checkupId={patient.checkupId} doctors={doctors} />
                            </Box>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <PatientsQueue serviceId={17} onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
};

export default Gynecologist;
