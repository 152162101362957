import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { Container, Box, Row, Column, Heading, Title } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { api } from '../helpers';
import { GlucoseLipoproteinForm } from '../forms/GlucoseLipoprotein';
import { PSAForm } from '../forms/PSA';
import { CBCForm } from '../forms/CBC';
import dayjs from 'dayjs';

function Analyzes() {
    const [patient, setPatient] = useState(null);
    const [patientPlan, setPatientPlan] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const onPatientSelect = async pat => {
        setPatient(pat);
        const plan = await api
            .get(`checkups/${pat.checkupId}/services`, { searchParams: { typeId: 2, status: 'pending' } })
            .json();
        setPatientPlan(plan);
    };

    useEffect(() => {
        api.get('users')
            .json()
            .then(list => {
                setDoctors(list);
            });
    }, []);

    return (
        <Container>
            <Row>
                <Column span={8}>
                {patient ? (
                    <>
                        <Title>
                            {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                        </Title>                        
                        {/*<Box>
                            <Heading size={5}>ОАК</Heading>
                            <CBCForm doctors={doctors} checkupId={patient.checkupId} />
                        </Box>*/}

                        <Box>
                            <Heading size={5}>Глюкоза и холестерин</Heading>
                            <GlucoseLipoproteinForm doctors={doctors} checkupId={patient.checkupId} />
                        </Box>
                        {/*<Box>
                            <Heading size={5}>ПСА</Heading>
                            <PSAForm doctors={doctors} checkupId={patient.checkupId} />
                        </Box>*/}
                    </> 
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <PatientsQueue serviceTypeId={2} onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
}

export default Analyzes;
