import { h } from 'preact';

import { Container, Field, Input, Radio, RadioGroup, Box, Row, Column, Panel, Heading } from '../components';
import Form from '../unform';
import { PatientsQueue } from '../components/PatientsQueue';

function Survey() {
    return (
        <Container>
            <Row>
                <Column span={8}>
                    <Box>
                        <Heading size={5}>Дополнительная анкета</Heading>
                        <Form>
                            <Field label="1. Состоит ли пациент на диспансерном учете по основному диагнозу? (DS1_PR)">
                                <RadioGroup name="q1">
                                    <Radio value="Y"> Да</Radio>
                                    <Radio value="N"> Нет</Radio>
                                </RadioGroup>
                            </Field>
                            <Field label="2. Направлен: (NAZ_R)">
                                <RadioGroup name="q2">
                                    <Radio value="a1">
                                        {' '}
                                        Направлен на консультацию в медицинскую организацию по месту прикрепления
                                    </Radio>
                                    <br />
                                    <Radio value="a2"> Направлен на консультацию в иную медицинскую организацию</Radio>
                                    <br />
                                    <Radio value="a3"> Направлен на обследование</Radio>
                                    <br />
                                    <Radio value="a4"> Направлен в дневной стационар</Radio>
                                    <br />
                                    <Radio value="a5"> Направлен на госпитализацию</Radio>
                                    <br />
                                    <Radio value="a6"> Направлен в реабилитационное отделение</Radio>
                                </RadioGroup>
                            </Field>
                            <Field label="3. Вид обследования (заполняется. Если в пункте 2 проставлен ответ «с») (NAZ_V)">
                                <RadioGroup name="q3">
                                    <Radio value="a1"> Лабораторная диагностика</Radio>
                                    <br />
                                    <Radio value="a2"> Инструментальная диагностика</Radio>
                                    <br />
                                    <Radio value="a3"> Методы лучевой диагностики, за исключением дорогостоящих</Radio>
                                    <br />
                                    <Radio value="a4">
                                        {' '}
                                        Дорогостоящие методы лучевой диагностики (КТ, МРТ, ангиография)
                                    </Radio>
                                </RadioGroup>
                            </Field>
                            <Field label="4. Диспансерное наблюдение: (PR_D_N)">
                                <RadioGroup name="q4">
                                    <Radio value="a1"> Состоит</Radio>
                                    <br />
                                    <Radio value="a2"> Взят</Radio>
                                    <br />
                                    <Radio value="a3"> Не подлежит диспансерному наблюдению</Radio>
                                </RadioGroup>
                            </Field>
                            <Field label="5. Диагноз сопутствующего заболевания (код по МКБ) (DS2)">
                                <Input name="q5" />
                            </Field>
                            <Field label="6. Установлен впервые: (DS2_PR)">
                                <RadioGroup name="q6">
                                    <Radio value="Y"> Да</Radio>
                                    <Radio value="N"> Нет</Radio>
                                </RadioGroup>
                            </Field>
                        </Form>
                    </Box>
                </Column>
                <Column span={4}>
                    <PatientsQueue />
                </Column>
            </Row>
        </Container>
    );
}

export default Survey;
