import { h } from 'preact';
import { useRef, useEffect, useState } from 'preact/hooks';
import clsx from 'clsx';

import { useField } from '../unform';
import { useCurrentState, useClickAway } from '../hooks';

const noop = item => item;

export const TagsInput = ({
    name,
    placeholder,
    emptyTitle = 'Ничего не найдено',
    onSearch,
    onChange,
    defaultItems,
    fontSz = 'medium',
    itemToLabel = noop,
    itemToId = noop,
    itemToTag = noop,
}) => {
    const controlRef = useRef();
    const inputRef = useRef();
    const { fieldName, defaultValue, registerField } = useField(name);
    const [state, setState, stateRef] = useCurrentState(defaultValue);
    const [items, setItems] = useState([]);
    const [focus, setFocus] = useState(false);

    useClickAway(controlRef, () => setFocus(false));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: stateRef,
            getValue: ref => stateRef.current,
            setValue: (ref, value) => {
                setState(value);
            },
            clearValue: ref => {
                setState([]);
            },
        });
    }, [stateRef, fieldName, registerField]);


    useEffect(()=> {
        if(defaultItems) setItems(defaultItems);
    }, [defaultItems])

    const handleInput = async ev => {
        if (!onSearch || ev.target.value.trim() === '') return;
        const newItems = await onSearch(ev.target.value);
        setItems(newItems);
    };

    const handleClick = item => {
        setFocus(false);
        setState(tags => [...tags, item]);
        onChange && onChange(stateRef.current);
        inputRef.current.value = '';
    };

    const handleRemove = item => {
        const id = itemToId(item);
        setState(tags => tags.filter(tag => itemToId(tag) !== id));
        onChange && onChange(stateRef.current);
    };


    return (
        <div className={clsx('tags-input', { 'is-active': focus })} ref={controlRef}>
            {state.map((item, index) => (
                <span className="tag" key={index} style={{fontSize: fontSz}}>
                    {itemToTag(item)}
                    <div className="delete is-small" onClick={() => handleRemove(item)} />
                </span>
            ))}
            <input
                className="input"
                type="text"
                ref={inputRef}
                placeholder={placeholder}
                onInput={handleInput}
                onFocus={() => setFocus(true)}
            />
            <div className="dropdown-menu">
                <div className="dropdown-content">
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <a
                                className="dropdown-item"
                                key={index}
                                onClick={() => handleClick(item)}
                                title={itemToLabel(item)}
                            >
                                {itemToLabel(item)}
                            </a>
                        ))
                    ) : (
                        <span className="dropdown-item empty-title">{emptyTitle}</span>
                    )}
                </div>
            </div>
        </div>
    );
};
