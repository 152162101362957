import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import dayjs from 'dayjs';

import { Panel } from './Panel';
import { Input } from './Input';
import { PersonIcon } from '../icons';
import { Button } from './Button';
import Form from '../unform';
import { api, plural, todayDate } from '../helpers';
import { Select } from './Select';
import { useCurrentState } from '../hooks';
import { Field } from './Field';
import { Checkbox } from './Checkbox';

const initialData = {
    addressGroupId: 0,
    query: '',
    orderBy: '',
    serviceDateMin: '',
    serviceDateMax: '',
    healthGroup1: false,
    healthGroup2: false, 
    healthGroup3A: false, 
    healthGroup3B: false, 
};

export const PatientsQueue = ({ serviceTypeId, serviceId, onPatientSelect, isCentralHealth }) => {
    const formRef = useRef(null);
    const [patients, setPatients] = useState([]);
    const [addressGroups, setAddressGroups] = useState([]);
    const [selectedId, setSelectedId] = useState(-1);
    const [, setFinished, finishedRef] = useCurrentState(false);


    const getQueue = async ({ 
        query, 
        serviceDateMin, 
        serviceDateMax, 
        addressGroupId, 
        orderBy, 
        healthGroup1, 
        healthGroup2, 
        healthGroup3A, 
        healthGroup3B 
    }) => {
        const resp = await api
            .get('queues/patients', {
                searchParams: Object.assign( { 
                        query, 
                        finished: finishedRef.current, 
                        orderBy,
                        healthGroup1,
                        healthGroup2,
                        healthGroup3A,
                        healthGroup3B,  
                        addressGroupId, 
                    },
                    serviceId && { serviceId },
                    serviceTypeId && { serviceTypeId },
                    serviceDateMin && { serviceDateMin },
                    serviceDateMax && { serviceDateMax },
                    isCentralHealth && { isCentralHealth }
                ),
            })
            .json();
        console.log(resp);
        setPatients(resp);

    };

    const getAddressGroups = async () => {
        const resp = await api.get('address-groups').json();
        setAddressGroups(resp);
    };

    useEffect(() => {
        getQueue(initialData);
        getAddressGroups();
    }, []);

    const selectPatient = async pat => {
        setSelectedId(pat.checkupId);
        onPatientSelect && onPatientSelect(pat);
    };

    const changePatientTab = index => {
        if (index === 0) {
            setFinished(false);
        } else if (index === 1) {
            setFinished(true);
        }
        getQueue(formRef.current.getData());
    };

    const patientList = patients.map(pat => {
        const age = dayjs().diff(pat.dateOfBirth, 'year');
        return (
            <Panel.Block active={pat.checkupId === selectedId} 
                    key={pat.checkupId} 
                    onClick={() => selectPatient(pat)}
                    style={{cursor: 'pointer'}} 
                    >
                <Panel.Icon style={{ color: pat.sex === 'Ж' ? '#f9a1b0' : '#37ccee' }}>
                    <PersonIcon />
                </Panel.Icon>
                {dayjs().diff(dayjs(pat.prevCheckupDate), 'years' === 0)? <span style={{color:'red'}}>⚠</span>:''} 
                    {pat.fullName} {dayjs(pat.dateOfBirth).format('DD.MM.YYYY')} ({age} {plural('год_года_лет', age)})
            </Panel.Block>
        );
    });

    return (
        <Panel color="info" className="has-background-white">
            <Panel.Heading>Список пациентов</Panel.Heading>
            <Panel.Block>
                <Form ref={formRef} initialData={initialData} onSubmit={getQueue}>
                    <Field>
                        <Input name="query" placeholder="Поиск по ФИО" />
                    </Field>
                    <Field label="Дата осмотра">
                        <div className="field has-addons">
                            <p className="control is-expanded">
                                <Input name="serviceDateMin" type="date" placeholder="Начало" />
                            </p>
                            <p className="control is-expanded">
                                <Input name="serviceDateMax" type="date" placeholder="Конец" />
                            </p>
                        </div>
                    </Field>
                    <Field>
                        {['1', '2', '3A', '3B'].map((name, index) => (
                            <Checkbox name={"healthGroup"+name} value={false}>
                                {name} группа
                            </Checkbox>
                        ))}
                    </Field>
                    <Field>
                        <Select name="addressGroupId">
                            <option value={0}>Не выбран</option>
                            {addressGroups.map(ag => (
                                <option key={ag.id} value={ag.id}>
                                    {ag.name}
                                </option>
                            ))}
                        </Select>
                    </Field>
                    <Field>
                        <Select name="orderBy">
                            <option value="">Без сортировки</option>
                            <option value="fullName">По ФИО</option>
                            <option value="dateOfBirth">По ДР</option>
                        </Select>
                    </Field>
                    <Field>
                        <Button fullwidth color="info">
                            Применить
                        </Button>
                    </Field>
                </Form>
            </Panel.Block>
            <Panel.Tabs onTabChange={changePatientTab}>
                <Panel.Tabs.Item title="Очередь" >{patientList}</Panel.Tabs.Item>
                <Panel.Tabs.Item title="Осмотрены">{patientList}</Panel.Tabs.Item>
            </Panel.Tabs>
        </Panel>
    );
};
