import { h, createContext } from 'preact';
import { useCallback, useContext, useEffect, useMemo, useState } from 'preact/hooks';

import { api } from '../helpers';

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export function AuthProvider(props) {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('sessions')
            .json()
            .then(user => setUser(user))
    }, []);

    const login = useCallback(form => {
        api.post('sessions', { json: form })
            .json()
            .then(user => {
                setError(false);
                if(user.error) setError(user.error);
                else setUser(user);
                console.log(user.error);
            })
            .catch(err => setError(err));
    }, []);

    const logout = useCallback(() => {
        api.delete('sessions');
        setUser(null);
    }, []);

    const value = useMemo(() => ({ user, error, login, logout }), [login, logout, user, error]);

    return <AuthContext.Provider value={value} {...props} />;
}
