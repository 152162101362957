import { h } from 'preact';

import { Container, Box, Row, Column, Title } from '../components';
import { CreatePatientForm } from '../forms/CreatePatient';

function NewPatient() {
    return (
        <Container>
            <Row centered>
                <Column span={6}>
                    <Box>
                        <Title>Общие сведения</Title>
                        <CreatePatientForm />
                    </Box>
                </Column>
            </Row>
        </Container>
    );
}

export default NewPatient;
