import { h } from 'preact';
import { useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Radio, RadioGroup } from '../components';
import { api, todayDate } from '../helpers';
import Form from '../unform';

const schema = Joi.object({
    result: Joi.number().integer(),
    person: Joi.number().integer(),
    date: Joi.date(),
});

export const StoolForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });

            const resp = await api.post(`measurements/${checkupId}/12`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field label="Кровь" horizontal>
                <Field>
                    <RadioGroup name="result">
                        <Radio value="1"> есть</Radio>
                        <Radio value="2"> нет</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field label="Анализ производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
};

StoolForm.defaultProps = {
    initialData: { result: '2', date: todayDate() },
};
