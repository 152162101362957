import ky from 'ky';
import dayjs from 'dayjs';

export const api = ky.create({ prefixUrl: `${location.origin}/api` });

const parseDigits = string => (string.match(/\d+/g) || []).join('');

export const hasRole = (user, role) => (user.roles & role) > 0;

export const todayDate = () => dayjs().format('YYYY-MM-DD');

export const formatDateToYMD = (date) => dayjs(date).format('YYYY-MM-DD');

export const yesterdayDate = () => dayjs().subtract(1, 'day').format('YYYY-MM-DD');

export const formatPhone = str => {
    const digits = parseDigits(str);
    const chars = digits.split('');
    return chars
        .reduce((phone, digit, index) => {
            if (index === 1) return `${phone}(${digit}`;
            if (index === 4) return `${phone})${digit}`;
            if (index === 7 || index === 9) return `${phone}-${digit}`;
            return `${phone}${digit}`;
        }, '')
        .substr(0, 15);
};

export const formatPolis = str => {
    const digits = parseDigits(str);
    const chars = digits.split('');
    return chars
        .reduce(
            (polis, digit, index) =>
                index === 4 || index === 8 || index === 12 ? `${polis} ${digit}` : `${polis}${digit}`,
            ''
        )
        .substr(0, 19);
};

export const getAge = pat => dayjs(todayDate()).diff(dayjs(pat.dateOfBirth).format("DD.MM.YYYY"), 'year');


export const formatPassport = str => {
    const digits = parseDigits(str);
    const chars = digits.split('');
    return chars
        .reduce(
            (passport, digit, index) => (index === 2 || index === 4 ? `${passport} ${digit}` : `${passport}${digit}`),
            ''
        )
        .substr(0, 12);
};

export const plural = (word, num) => {
    const forms = word.split('_');
    return num % 10 === 1 && num % 100 !== 11
        ? forms[0]
        : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
        ? forms[1]
        : forms[2];
};
