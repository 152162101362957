import dayjs from 'dayjs';
import Joi from 'joi';
import {h, Fragment} from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Autocomplete, Box, Button, Column, Field, Input, Radio, RadioGroup, Row, Select, Title } from '../components';
import CheckupsTable from '../forms/CheckupsTable';
import { formatPhone, formatPolis, formatPassport, api, plural } from '../helpers';
import { useRifm } from '../rifm';
import Form from '../unform';

const schemaGeneral = Joi.object({
    fullName: Joi.string(),
    dateOfBirth: Joi.date(),
    sex: Joi.string().valid('М', 'Ж'),
    phone: Joi.string().allow(''),
    polis: Joi.string().allow(''),
    passport: Joi.string().allow(''),
    address: Joi.string().allow(''),
    room: Joi.string().allow(''),
})

const schemaAdditional = Joi.object({
    attachedNum: Joi.number(),
    liveIn: Joi.number(),
    socialStatus: Joi.number(),
    education: Joi.string().allow(''),
    job: Joi.string().allow(''),
    position: Joi.string().allow(''),
})


export default PatientProfile = ({patientId, checkupID}) => {

    const [patient, setPatient] = useState(null);
    const [phone, setPhone] = useState('');
    const [polis, setPolis] = useState('');
    const [passport, setPassport] = useState('');
    const [lastCheckup, setLastCheckup] = useState(null);
    const [currentCheckup, setCurrentCheckup] = useState(null);
    const [patientPlan, setPatientPlan] = useState([]);
    const [centralHealth, setCentralHealth] = useState([]);
    const [loading, setLoading] = useState({general: false, additional: false})
    const [error, setError] = useState({general: {}, additional: {}})


    const phoneRifm = useRifm({
        value: phone,
        onChange: setPhone,
        format: formatPhone,
    });

    const polisRifm = useRifm({
        value: polis,
        onChange: setPolis,
        format: formatPolis,
    });

    const passportRifm = useRifm({
        value: passport,
        onChange: setPassport,
        format: formatPassport,
    });

    useEffect(async () => {
        try {
            console.log(patientId)
            const pat = await api.get(`patients/${patientId}`).json();
            console.log(pat);           
            setLastCheckup(pat.checkups[0]);
            setPatient(pat);
            setPhone(pat.phone);
            setPolis(pat.polis);
            setPassport(pat.passport);
            if(checkupID) getCheckup(checkupID)
        } catch(e) {
            console.log(e);
        }
    }, []);

    const searchAddress = async str => {
        const items = await api.get('addresses', { searchParams: { query: str } }).json();
        return items;
    };

    const onAddressClick = addr => {
        formRef.current.setFieldValue('address', `${addr.street} ${addr.building}`);
    };

    const handleEditGeneralInfo = async (data) => {
        setLoading({...loading, general: true})
        setError({additional: {}, general: {}});
        try {
            console.log(data);
            const result = await schemaGeneral.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`patients/editgeneral/${patient.id}`, {json: result}).json();
            if(resp.error) throw(resp.error);
        } catch(err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setError({...error, general: validationErrors});
            }
            else if(err.address) setError({...error, general: {address: err.address}});
        }
        setLoading({...loading, general: false})
    }

    const handleEditAdditionalInfo = async (data) => {
        setLoading({...loading, additional: true})
        try {
            console.log(data);
            const result = await schemaAdditional.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`checkups/editadditional/${lastCheckup.id}`, {json: result}).json();
        } catch(err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setError({...error, additional: validationErrors});
            } 
        }
        setLoading({...loading, additional: false})
    }

    const getCheckup = async (checkupid) => {
        const plan = await api.get(`checkups/${checkupid}/services`).json();
        const ch = plan.filter(val => {
            if(val.serviceId >= 43 && val.serviceId <= 47) return val;
        })
        const check = await api.get(`checkups/${checkupid}`).json();
        setCentralHealth(ch);
        setPatientPlan(plan.filter(val => {if(!(val.serviceId >= 43 && val.serviceId <= 47)) return val;}));
        setCurrentCheckup(check);
    }

    const handleCheckup = async (e) => {
        const checkupid = e.target.value;
        if(checkupid == -1) setCurrentCheckup(null);
        else {
            getCheckup(checkupid)
        }
    }

    return <>
    {patient && lastCheckup && <>
        {!checkupID &&
            <Column>
                <Title>
                    <p>{patient.fullName}</p>
                    {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')} 
                    ({dayjs().diff(patient.dateOfBirth, 'year')} {plural('год_года_лет', dayjs().diff(patient.dateOfBirth, 'year'))})
                </Title>
            </Column> 
        }
        <Row centered>
            <Column span={5} >
                <Box>
                    <Title>Основная информация</Title>
                    <Form onSubmit={handleEditGeneralInfo} initialData={{...patient, dateOfBirth: dayjs(patient.dateOfBirth).format('YYYY-MM-DD')}}>
                        <Field label="Полное имя" horizontal>
                            <Input name="fullName"></Input>
                        </Field>
                        <Field label="Пол" horizontal>
                            <RadioGroup name="sex">
                                <Radio value={"М"}>Мужской</Radio>
                                <Radio value={"Ж"}>Женский</Radio>
                            </RadioGroup>
                        </Field>
                        <Field label="Дата рождения" horizontal error={error.general.dateOfBirth}>
                            <Field error={error.general.dateOfBirth}>
                                <Input name="dateOfBirth" type="date"></Input>
                            </Field>
                        </Field>
                        <Field horizontal label="Полис">
                            <Field error={error.general.polis}>
                                <Input
                                    name="polis"
                                    value={polisRifm.value}
                                    onInput={polisRifm.onChange}
                                    placeholder="0000 0000 0000 0000"
                                />
                            </Field>
                        </Field>
                        <Field horizontal label="Телефон">
                            <Field error={error.general.phone}>
                                <Input
                                    name="phone"
                                    value={phoneRifm.value}
                                    onInput={phoneRifm.onChange}
                                    placeholder="7(123)456-78-90"
                                />
                            </Field>
                        </Field>
                        <Field horizontal label="Паспорт">
                            <Field error={error.general.passport}>
                                <Input
                                    name="passport"
                                    value={passportRifm.value}
                                    onInput={passportRifm.onChange}
                                    placeholder="12 34 567890"
                                />
                            </Field>
                        </Field>
                        <Field horizontal label="Адрес" >
                            <div className="field has-addons">
                                <Field error={error.general.address}>
                                <Autocomplete
                                    name="address"
                                    placeholder="улица, дом"
                                    onSearch={searchAddress}
                                    onChange={onAddressClick}
                                >
                                    {({ item }) => `${item.street}, ${item.building}`}
                                </Autocomplete>
                                </Field>
                                <p className="control">
                                    <Field error={error.general.room}>
                                        <Input name="room" placeholder="кв" />
                                    </Field>
                                </p>
                            </div>
                        </Field>
                        <Field horizontal>
                            <Button color="primary" loading={loading.general}>Редактировать</Button>
                        </Field>
                    </Form>
                </Box>
            </Column>
            <Column span={5}>
                <Box>
                    <Title subtitle>Дополнительная информация</Title>
                    <Form initialData={lastCheckup} onSubmit={handleEditAdditionalInfo}>
                        <Field horizontal label="№ поликлиники по месту жительства">
                            <Input name="attachedNum" type="number"></Input>
                        </Field>
                        <Field horizontal label="Живёт пост.">
                            <RadioGroup name = "liveIn">
                                <Radio value={1}>Город</Radio>
                                <Radio value={2}>Село</Radio>
                            </RadioGroup>
                        </Field>
                        <Field horizontal label="Соц. положение">
                            <RadioGroup name = "socialStatus">
                                <Radio value={1}>служащий</Radio>
                                <Radio value={2}>рабочий</Radio>
                                <Radio value={3}>учащийся</Radio>
                                <Radio value={4}>неработающий</Radio>
                            </RadioGroup>
                        </Field>
                        <Field horizontal label="Образование">
                            <Input name="education"></Input>
                        </Field>
                        <Field horizontal label="Место работы">
                            <Input name="job"></Input>
                        </Field>
                        <Field horizontal label="Профессия, должность">
                            <Input name="position"></Input>
                        </Field>
                        <Field horizontal>
                            <Button color="primary" loading={loading.additional}>Редактировать</Button>
                            <Field error={error.additional.globalError}/>
                        </Field>
                    </Form>
                </Box>
            </Column>
        </Row>
        <Row centered>
            <Column span={8}>
                <Box>
                    <Title>Случаи</Title>
                    <Form initialData={{checkups: checkupID}}>
                        <Select name="checkups" onChange={handleCheckup}>
                            {!checkupID && <option value={-1}>Не выбран</option>}
                            {patient.checkups.map(val => {
                                return <>
                                    <option value={val.id}>случай от {dayjs(val.startDate).format('DD.MM.YYYY')}, закончен {val.endDate?dayjs(val.startDate).format('DD.MM.YYYY'):'- (текущий)'} </option>
                                </>
                            })}
                        </Select>
                    </Form>
                    {currentCheckup?<>
                        <CheckupsTable checkups={centralHealth} isCentralHealth></CheckupsTable>
                        <CheckupsTable checkups={patientPlan}></CheckupsTable>
                    </>:''}
                </Box>
            </Column>
            <Column span={2}/>
        </Row>
        </>}
    </>
}