import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { ECGForm } from '../forms/ECG';
import { api } from '../helpers';

const ECG = () => {
    const [patient, setPatient] = useState(null);
    const [doctors, setDoctors] = useState([]);

    useEffect(() => {
        api.get('users')
            .json()
            .then(list => {
                setDoctors(list);
            });
    }, []);

    const onPatientSelect = async pat => {
        setPatient(pat);
    };

    return (
        <Container>
            <Row centered>
                <Column span={8}>
                    {patient ? (
                        <>
                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                            </Title>
                            <Box>
                                <ECGForm checkupId={patient.checkupId} doctors={doctors} />
                            </Box>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <PatientsQueue serviceId={10} onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
};

export default ECG;
