import { useContext, useEffect, useMemo, useCallback } from 'preact/hooks';
import dlv from 'dlv';

import { FormContext } from './context';

export function useField(name) {
    const { initialData, errors, scopePath, unregisterField, registerField, clearFieldError } = useContext(FormContext);

    if (!name) {
        throw new Error('You need to provide the "name" prop.');
    }

    const fieldName = useMemo(() => (scopePath ? `${scopePath}.${name}` : name), [name, scopePath]);

    const defaultValue = useMemo(() => dlv(initialData, fieldName), [fieldName, initialData]);

    const error = useMemo(() => errors[fieldName], [errors, fieldName]);

    const clearError = useCallback(() => {
        clearFieldError(fieldName);
    }, [clearFieldError, fieldName]);

    useEffect(() => () => unregisterField(fieldName), [fieldName, unregisterField]);

    return {
        fieldName,
        registerField,
        defaultValue,
        clearError,
        error,
    };
}
