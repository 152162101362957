import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Title = ({ tag = 'h1', color, size, subtitle, ...props }) => (
    <Element
        tag={tag}
        className={clsx(!subtitle?'title':'subtitle', {
            [`is-${size}`]: size,
        })}
        {...props}
    />
);
