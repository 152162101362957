import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { AnthropometryForm } from '../forms/Anthropometry';

const Anthropometry = () => {
    const [patient, setPatient] = useState(null);

    const onPatientSelect = async pat => {
        setPatient(pat);
    };

    return (
        <Container>
            <Row centered>
                <Column span={4}>
                    {patient ? (
                        <>
                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                            </Title>
                            <Box>
                                <AnthropometryForm checkupId={patient.checkupId} />
                            </Box>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>
                <Column span={4}>
                    <PatientsQueue serviceId={3} onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
};

export default Anthropometry;
