import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';

import { useField } from '../unform';
import { Element } from './Element';

export const Textarea = ({ name, placeholder, disabled, ...props }) => {
    const textareaRef = useRef();
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textareaRef,
            getValue: ref => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value;
            },
            clearValue: ref => {
                ref.current.value = '';
            },
        });
    }, [fieldName, registerField]);

    return (
        <Element
            tag="textarea"
            className="textarea"
            domRef={textareaRef}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            rows={3}
            {...props}
        />
    );
};
