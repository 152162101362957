import { h } from 'preact';
import { useState, useRef } from 'preact/hooks';
import Joi from 'joi';

import { Checkbox, Field, Input, Radio, RadioGroup, Button, Autocomplete, Heading, Box } from '../components';
import { formatPhone, formatPolis, formatPassport, api } from '../helpers';
import Form from '../unform';

const schema = Joi.object({
    fullName: Joi.string(),
    login: Joi.string().min(3).uppercase(),
    password: Joi.string().min(6),
    roles: Joi.number(),
});

export const CreateUserForm = ({ updateUsers }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data, { reset }) => {
        console.log(data);
        setLoading(true);
        setErrors({});
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });

            const resp = await api.post('users', { json: result });
            console.log(resp);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            } else setErrors({ userExists: 'Пользователь с таким логином уже существует!' });
        }
        updateUsers();
        setLoading(false);
    };

    return (
        <Box>
            <Heading size={5}>Создание пользователя</Heading>
            <Form onSubmit={handleSubmit}>
                <Field horizontal label="ФИО" required>
                    <Field error={errors.fullName}>
                        <Input name="fullName" placeholder="Иванов Иван Иванович" />
                    </Field>
                </Field>
                <Field horizontal label="Логин" required>
                    <Field error={errors.login}>
                        <Input name="login" placeholder="ABC" />
                    </Field>
                </Field>
                <Field horizontal label="Пароль" required>
                    <Field error={errors.password}>
                        <Input name="password" />
                    </Field>
                </Field>
                <Field horizontal label="Роль" required>
                    <Field error={errors.roles}>
                        <Input name="roles" type="number" min="0" />
                    </Field>
                </Field>
                <Field horizontal>
                    <Field error={errors.userExists}>
                        <Button fullwidth color="primary" loading={loading}>
                            Создать
                        </Button>
                    </Field>
                </Field>
            </Form>
        </Box>
    );
};
