import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Checkbox, Input, Radio, Table, RadioGroup, Button, TagsInput } from '../components';
import { api } from '../helpers';
import Form from '../unform';

export const Under65Form = ({ checkupId, initialData }) => {
    const [saved, setSaved] = useState(false);

    const handleSubmit = async (data, { reset }) => {
        console.log(data);
        const sum = [data.q25, data.q26, data.q27].map(Number).reduce((acc, x) => acc + x);

        try {
            const resp = await api.post(`measurements/${checkupId}/1`, { json: { ...data, sum } }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);
        }
    };

    const searchDisease = async str => {
        const items = await api.get('diseases', { searchParams: { query: str } }).json();
        return items;
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form initialData={initialData} onSubmit={handleSubmit}>
            <Table bordered>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Вопрос</th>
                        <th>Ответ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td colSpan="2">Говорил ли Вам врач когда-либо, что у Вас имеется</td>
                    </tr>
                    <tr>
                        <td>1.1</td>
                        <td>гипертоническая болезнь (повышенное артериальное давление)?</td>
                        <td>
                            <Checkbox name="q1_1_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения давления? (к 21 вопросу)</td>
                        <td>
                            <RadioGroup name="q1_1_2">
                                <Radio value="N"> Нет</Radio>
                                <Radio value="Y1"> Да, помогает</Radio>
                                <Radio value="Y2"> Да, не помогает</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>1.2</td>
                        <td>ишемическая болезнь сердца (стенокардия)?</td>
                        <td>
                            <Checkbox name="q1_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.3</td>
                        <td>цереброваскулярное заболевание (заболевание сосудов головного мозга)?</td>
                        <td>
                            <Checkbox name="q1_3"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.4</td>
                        <td>
                            хроническое заболевание бронхов или легких (хронический бронхит, эмфизема, бронхиальная
                            астма)?
                        </td>
                        <td>
                            <Checkbox name="q1_4"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.5</td>
                        <td>туберкулез (легких или иных локализаций)?</td>
                        <td>
                            <Checkbox name="q1_5"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.6</td>
                        <td>сахарный диабет или повышенный уровень сахара в крови?</td>
                        <td>
                            <Checkbox name="q1_6_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения уровня сахара?</td>
                        <td>
                            <Checkbox name="q1_6_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.7</td>
                        <td>заболевания желудка (гастрит, язвенная болезнь)?</td>
                        <td>
                            <Checkbox name="q1_7"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.8</td>
                        <td>хроническое заболевание почек?</td>
                        <td>
                            <Checkbox name="q1_8"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>1.9</td>
                        <td>злокачественное новообразование?</td>
                        <td>
                            <Checkbox name="q1_9_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то какое?</td>
                        <td>
                            <TagsInput
                                name="q1_9_2"
                                placeholder="МКБ-10"
                                onSearch={searchDisease}
                                itemToTag={item => item.code}
                                itemToLabel={item => `${item.code} ${item.name}`}
                                itemToId={item => item.id}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>1.10</td>
                        <td>повышенный уровень холестерина?</td>
                        <td>
                            <Checkbox name="q1_10_1"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Если «Да», то принимаете ли Вы препараты для снижения уровня холестерина?</td>
                        <td>
                            <Checkbox name="q1_10_2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Был ли у Вас инфаркт миокарда?</td>
                        <td>
                            <Checkbox name="q2"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Был ли у Вас инсульт?</td>
                        <td>
                            <Checkbox name="q3"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>
                            Был ли инфаркт миокарда или инсульт у Ваших близких родственников в молодом или среднем
                            возрасте (до 65 лет у матери или родных сестер или до 55 лет у отца или родных братьев)?
                        </td>
                        <td>
                            <Checkbox name="q4"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>
                            Были ли у Ваших близких родственников в молодом или среднем возрасте злокачественные
                            новообразования (легкого, желудка, кишечника, толстой или прямой кишки, предстательной
                            железы, молочной железы, матки, опухоли других локализаций) или полипоз желудка, семейный
                            аденоматоз/диффузный полипоз толстой кишки? (нужное подчеркнуть)
                        </td>
                        <td>
                            <Checkbox name="q5"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>
                            Возникает ли у Вас, когда поднимаетесь по лестнице, идете в гору или спешите, или при выходе
                            из теплого помещения на холодный воздух, боль или ощущение давления, жжения, тяжести или
                            явного дискомфорта за грудиной и (или) в левой половине грудной клетки, и (или) в левом
                            плече, и (или) в левой руке?
                        </td>
                        <td>
                            <Checkbox name="q6"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>
                            Если на вопрос 6 ответ «Да», то указанные боли/ощущения/дискомфорт исчезают сразу или в
                            течение не более чем 20 мин после прекращения ходьбы/адаптации к холоду/ в тепле/в покое и
                            (или) они исчезают через 1-5 мин после приема нитроглицерина
                        </td>
                        <td>
                            <Checkbox name="q7"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>
                            Возникала ли у Вас когда-либо внезапная кратковременная слабость или неловкость при движении
                            в одной руке (ноге) либо руке и ноге одновременно так, что Вы не могли взять или удержать
                            предмет, встать со стула, пройтись по комнате?
                        </td>
                        <td>
                            <Checkbox name="q8"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>
                            Возникало ли у Вас когда-либо внезапное без явных причин кратковременное онемение в одной
                            руке, ноге или половине лица, губы или языка?
                        </td>
                        <td>
                            <Checkbox name="q9"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Возникала ли у Вас когда-либо внезапно кратковременная потеря зрения на один глаз?</td>
                        <td>
                            <Checkbox name="q10"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>
                            Бывают ли у Вас ежегодно периоды ежедневного кашля с отделением мокроты на протяжении
                            примерно 3-х месяцев в году?
                        </td>
                        <td>
                            <Checkbox name="q11"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>
                            Бывают ли у Вас свистящие или жужжащие хрипы в грудной клетке при дыхании, не проходящие при
                            откашливании?
                        </td>
                        <td>
                            <Checkbox name="q12"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Бывало ли у Вас когда-либо кровохарканье?</td>
                        <td>
                            <Checkbox name="q13"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>
                            Беспокоят ли Вас боли в области верхней части живота (в области желудка), отрыжка, тошнота,
                            рвота, ухудшение или отсутствие аппетита?
                        </td>
                        <td>
                            <Checkbox name="q14"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Бывает ли у Вас неоформленный (полужидкий) черный или дегтеобразный стул?</td>
                        <td>
                            <Checkbox name="q15"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>
                            Похудели ли Вы за последнее время без видимых причин (т.е. без соблюдения диеты или
                            увеличения физической активности и пр.)?
                        </td>
                        <td>
                            <Checkbox name="q16"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Бывает ли у Вас боль в области заднепроходного отверстия?</td>
                        <td>
                            <Checkbox name="q17"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>Бывают ли у Вас кровяные выделения с калом?</td>
                        <td>
                            <Checkbox name="q18"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>Курите ли Вы? (курение одной и более сигарет в день)</td>
                        <td>
                            <Checkbox name="q19"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>Если Вы курите, то сколько в среднем сигарет в день выкуриваете?</td>
                        <td>
                            <Input name="q20" />
                        </td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>
                            Сколько минут в день Вы тратите на ходьбу в умеренном или быстром темпе (включая дорогу до
                            места работы и обратно)?
                        </td>
                        <td>
                            <RadioGroup name="q21">
                                <Radio value="lt30"> До 30 минут</Radio>
                                <br />
                                <Radio value="ge30"> 30 минут и более</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>Присутствует ли в Вашем ежедневном рационе 400-500 г сырых овощей и фруктов?</td>
                        <td>
                            <Checkbox name="q22"> Нет</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>Имеете ли Вы привычку подсаливать приготовленную пищу, не пробуя ее?</td>
                        <td>
                            <Checkbox name="q23"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>
                            Принимали ли Вы за последний год психотропные или наркотические вещества без назначения
                            врача?
                        </td>
                        <td>
                            <Checkbox name="q24"> Да</Checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>Как часто Вы употребляете алкогольные напитки?</td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            <RadioGroup name="q25">
                                <Radio value="0"> Никогда</Radio>
                                <br />
                                <Radio value="1"> Раз в месяц и реже</Radio>
                                <br />
                                <Radio value="2"> 2-4 раза в месяц</Radio>
                                <br />
                                <Radio value="3"> 2-3 раза в неделю</Radio>
                                <br />
                                <Radio value="4"> ≥ 4 раз в неделю</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>Какое количество алкогольных напитков (сколько порций) вы выпиваете обычно за один раз?</td>
                        <td>
                            <RadioGroup name="q26">
                                <Radio value="0"> 1-2 порции</Radio>
                                <br />
                                <Radio value="1"> 3-4 порции</Radio>
                                <br />
                                <Radio value="2"> 5-6 порций</Radio>
                                <br />
                                <Radio value="3"> 7-9 порций</Radio>
                                <br />
                                <Radio value="4"> ≥ 10 порций</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>Как часто Вы употребляете за один раз 6 или более порций?</td>
                        <td>
                            <RadioGroup name="q27">
                                <Radio value="0"> Никогда</Radio>
                                <br />
                                <Radio value="1"> Раз в месяц и реже</Radio>
                                <br />
                                <Radio value="2"> 2-4 раза в месяц</Radio>
                                <br />
                                <Radio value="3"> 2-3 раза в неделю</Radio>
                                <br />
                                <Radio value="4"> ≥ 4 раз в неделю</Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>
                            Есть ли у Вас другие жалобы на свое здоровье, не вошедшие в настоящую анкету и которые Вы бы
                            хотели сообщить врачу (фельдшеру)
                        </td>
                        <td>
                            <Checkbox name="q28"> Да</Checkbox>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Button fullwidth color="primary">
                Сохранить
            </Button>
        </Form>
    );
};

Under65Form.defaultProps = {
    initialData: { q1_1_2: 'N', q21: 'ge30', q25: '0', q26: '0', q27: '0', q1_9_2: [] },
};
