import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';
import { PanelBlock } from './PanelBlock';
import { PanelHeading } from './PanelHeading';
import { PanelIcon } from './PanelIcon';
import { PanelTabs } from './PanelTabs';

export const Panel = ({ color, className, ...props }) => (
    <Element
        tag="nav"
        className={clsx('panel', className, {
            [`is-${color}`]: color,
        })}
        {...props}
    />
);

Panel.Block = PanelBlock;
Panel.Heading = PanelHeading;
Panel.Icon = PanelIcon;
Panel.Tabs = PanelTabs;
