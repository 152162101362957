import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Button = ({
    tag = 'button',
    color,
    size,
    fullwidth,
    outlined,
    loading,
    rounded,
    inverted,
    disabled,
    selected,
    onClick,
    isStatic,
    ...props
}) => (
    <Element
        tag={tag}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        className={clsx('button', {
            [`is-${color}`]: color,
            [`is-${size}`]: size,
            'is-fullwidth': fullwidth,
            'is-outlined': outlined,
            'is-inverted': inverted,
            'is-rounded': rounded,
            'is-loading': loading,
            'is-selected': selected,
            'is-static': isStatic,
        })}
        {...props}
    />
);
