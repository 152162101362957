import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    person: Joi.string(),
    date: Joi.date(),
    q1: Joi.array(),
    q2: Joi.string().allow(''),
    q3: Joi.array(),
    q4: Joi.array(),
    q5: Joi.array(),
    q6: Joi.array(),
    q7: Joi.array(),
    q8: Joi.array(),
    q9: Joi.string().allow(''),
    q10: Joi.array(),
    q11: Joi.array(),
    q12: Joi.array(),
    q13: Joi.number(),
    q13_1: Joi.bool(),
    q13_2: Joi.bool(),
    q14: Joi.number(),
    q15: Joi.number(),
    q15_1: Joi.number().allow(''),
    q15_2: Joi.number().allow(''),
    q15_3: Joi.bool(),
    q15_4: Joi.bool(),
    q16: Joi.number(),
    q17_1: Joi.bool(),
    q17_2: Joi.bool(),
    q17_3: Joi.bool(),
    q18: Joi.number(),
    q19: Joi.number(),
    q20_1: Joi.bool(),
    q20_2: Joi.bool(),
    q20_3: Joi.bool(),
    q20_4: Joi.bool(),
    q20_5: Joi.bool(),
    q20_6: Joi.bool(),
    q20_7: Joi.bool(),
    q20_8: Joi.string().allow(''),
    q20_9: Joi.string().allow(''),
    q21: Joi.number(),
    q22: Joi.number(),
    q23: Joi.number(),
    q24: Joi.number(),
    q24_1: Joi.string().allow(''),
    q25_1: Joi.bool(),
    q25_2: Joi.bool(),
    q25_3: Joi.bool(),
    q25_4: Joi.bool(),
    q25_5: Joi.bool(),
    q25_6: Joi.bool(),
    q25_7: Joi.bool(),
    q25_8: Joi.string().allow(''),
    q26_1: Joi.bool(),
    q26_2: Joi.bool(),
    q26_3: Joi.bool(),
    q26_4: Joi.bool(),
    q26_5: Joi.bool(),
    q26_6: Joi.string().allow(''),
    q27_1: Joi.bool(),
    q27_2: Joi.bool(),
    q27_3: Joi.bool(),
    q27_4: Joi.bool(),
    q27_5: Joi.bool(),
    q27_6: Joi.bool(),
    q27_7: Joi.string().allow(''),
});

export const GeneralInfoAnamnesisForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [expandedFields, setExpandedFields] = useState([]);
    const [deseases, setDeseases] = useState([]);

    initialData.date = formatDateToYMD(initialData.date);

    if (saved) {
        return 'Сохранено';
    }

    useEffect(() => {
        api.get('chdeseases')
            .json()
            .then(list => {
                setDeseases(list);
            })
    },[]);

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/45`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    const expandField = (name, expandedVal) => val => {
        if (val === expandedVal) {
            setExpandedFields(prev => [...prev, name]);
        } else {
            setExpandedFields(prev => prev.filter(field => name !== field));
        }
    };

    const searchDisease = async (str,type) => {
        const items = deseases.filter(x => x.typeid == type);
        return items;
    };

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
           <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field label="Наследственные заболевания" horizontal>            
                <TagsInput
                        name="q1"
                        defaultItems={deseases.filter(x => x.typeid == 1)}
                        itemToTag={item => item.name}
                        itemToLabel={item => `${item.name}`}
                        itemToId={item => item.id}
                    />
            </Field>
            <Field label="Перенесенные заболевания" horizontal>
                <Input name="q2"></Input>
            </Field>
            <Field label="Хронические заболевания: ">
                <Field label ="Органов дыхания" horizontal>
                    <TagsInput
                            name="q3"
                            defaultItems={deseases.filter(x => x.typeid == 2)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="Сердечно-сосудистые заболевания" horizontal>
                    <TagsInput
                            name="q4"
                            defaultItems={deseases.filter(x => x.typeid == 3)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="Органов пищеварения" horizontal>
                    <TagsInput
                            name="q5"
                            defaultItems={deseases.filter(x => x.typeid == 4)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="Почек и мочевыделительных путей" horizontal>
                    <TagsInput
                            name="q6"
                            defaultItems={deseases.filter(x => x.typeid == 5)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="ЦНС" horizontal>
                    <TagsInput
                            name="q7"
                            defaultItems={deseases.filter(x => x.typeid == 6)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="Эндокринной системы" horizontal>
                    <TagsInput
                            name="q8"
                            defaultItems={deseases.filter(x => x.typeid == 7)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
                <Field label ="Новообразования" horizontal>
                    <Input name="q9"></Input>
                </Field>
                <Field label ="Др. заболевания" horizontal>
                    <TagsInput
                            name="q10"
                            defaultItems={deseases.filter(x => x.typeid == 10)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                </Field>
            </Field>
            <Field label = "Перенесенные травмы" horizontal>
                <TagsInput
                            name="q11"
                            defaultItems={deseases.filter(x => x.typeid == 8)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
            </Field>
            <Field label = "Перенесенные операции" horizontal>
                <TagsInput
                            name="q12"
                            defaultItems={deseases.filter(x => x.typeid == 9)}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
            </Field>
            <Field label = "Употребление алкоголя: ">
                <Field horizontal>
                        <Checkbox name="q13_1"> Крепкие алкогольные напитки </Checkbox>
                        <Checkbox name="q13_2"> Слабоалкогольные напитки </Checkbox>
                </Field>
                <Field horizontal>
                    <RadioGroup name="q14">
                        <Radio value={1}> Случайное </Radio>
                        <Radio value={2}> Мало </Radio>
                        <Radio value={3}> Много </Radio>
                        <Radio value={4}> Часто </Radio>
                        <Radio value={5}> Не потребляет </Radio>
                    </RadioGroup> 
                </Field> 
            </Field>
            <Field label="Курение: ">
                <Field horizontal>
                    <RadioGroup name="q15" onChange={expandField('q15', 2)}>
                        <Radio value={1}> не курит </Radio>
                        <Radio value={2}> курит </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q15') })}>
                    <Input name="q15_1" type="number" placeholder="с лет"/>
                    <Input name="q15_2" type="number" placeholder="штук в день"/>
                    <Checkbox name="q15_3">Сигареты</Checkbox>
                    <Checkbox name="q15_4">Электронные сигареты</Checkbox>                                
                </Field>
            </Field>
            <Field label="Питание: ">
                <Field label="Режим питания" horizontal>
                    <RadioGroup name="q16">
                        <Radio value={1}> регулярный </Radio>
                        <Radio value={2}> нерегулярный </Radio>
                    </RadioGroup>
                </Field>
                <Field label="Характер питания" horizontal>
                    <Checkbox name="q17_1">белки</Checkbox>
                    <Checkbox name="q17_2">жиры</Checkbox>
                    <Checkbox name="q17_3">углеводы</Checkbox>
                </Field>
            </Field>
            <Field label="Сон" horizontal>
                <RadioGroup name="q18">
                    <Radio value={1}>7-9 часов</Radio>
                    <Radio value={2}>менее 7 часов</Radio>
                    <Radio value={3}>более 9 часов</Radio>
                </RadioGroup>
            </Field>
            <Field label="Занятие физкультурой и спортом: ">
                <Field horizontal>
                    <RadioGroup name="q19">
                        <Radio value={1}>систематические</Radio>
                        <Radio value={2}>случайные</Radio>
                        <Radio value={3}>не занимается</Radio>
                    </RadioGroup>
                </Field>
                <Field horizontal>
                    <Checkbox name="q20_1">физкультура</Checkbox>
                    <Checkbox name="q20_2">утренняя гимнастика</Checkbox>
                    <Checkbox name="q20_3">бег</Checkbox>
                    <Checkbox name="q20_4">ходьба на лыжах</Checkbox>
                    <Checkbox name="q20_5">езда на велосипеде</Checkbox>
                    <Checkbox name="q20_6">плавание</Checkbox>
                    <Checkbox name="q20_7">игра в теннис</Checkbox>
                </Field>
                <Field horizontal>
                    <Input name="q20_8" placeholder="другое"></Input>
                    <Input name="q20_9" placeholder="вид спорта"></Input>
                </Field>             
            </Field>
            <Field>
                <Field label="Активность образа жизни" horizontal>
                    <RadioGroup name="q21">
                        <Radio value={1}>активный</Radio>
                        <Radio value={2}>пассивный</Radio>
                        <Radio value={3}>смешанный</Radio>                        
                    </RadioGroup>
                </Field>
                <Field label="Характер отдыха" horizontal>
                    <RadioGroup name="q22">
                        <Radio value={1}>активный</Radio>
                        <Radio value={2}>пассивный</Radio>
                        <Radio value={3}>смешанный</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field label="Характер труда: ">
                <Field label="Работа" horizontal>
                    <RadioGroup name="q23">
                        <Radio value={1}>нормированный рабочий день</Radio>
                        <Radio value={2}>ненормированный рабочий день</Radio>
                    </RadioGroup>
                </Field>
                <Field horizontal>
                    <RadioGroup name = "q24" onChange={expandField('q24', 4)}>
                        <Radio value= {1}> сидячий</Radio>
                        <Radio value= {2}> на ногах</Radio>
                        <Radio value= {3}> разъезды</Radio>
                        <Radio value= {4}> другое </Radio>
                    </RadioGroup>
                </Field>
                <Field horizontal>
                    <Field className={clsx('details', { 'is-active': expandedFields.includes('q24') })}>
                        <Input name="q24_1"/>                             
                    </Field>
                </Field>
                <Field label="Производственные вредности" horizontal>
                    <Checkbox name="q25_1">химические факторы</Checkbox>
                    <Checkbox name="q25_2">биологические факторы</Checkbox>
                    <Checkbox name="q25_3">шум</Checkbox>
                    <Checkbox name="q25_4">вибрация</Checkbox>
                    <Checkbox name="q25_5">статическое напряжение</Checkbox>
                    <Checkbox name="q25_6">перенапряжение зрительного аппарата</Checkbox>
                    <Checkbox name="q25_7">перенапряжение голосового аппарата</Checkbox>
                </Field>
                <Field horizontal>
                    <Input name = "q25_8" placeholder="др."></Input>
                </Field>
            </Field>
            <Field label="Цель настоящего обращения: ">
                <Field horizontal>
                        <Checkbox name="q26_1">Получение информации о здоровом образе жизни</Checkbox>
                        <Checkbox name="q26_2">Правильное питание</Checkbox>
                        <Checkbox name="q26_3">Отказ от табакокурения</Checkbox>
                        <Checkbox name="q26_4">Отказ от приема алкоголя</Checkbox>
                        <Checkbox name="q26_5">Получение информации о заболевании</Checkbox>
                </Field>
                <Field horizontal label="другое">
                    <Input name="q26_6"/>                             
                </Field>
            </Field>
            <Field label="Источник получения информации">
                <Checkbox name="q27_1">радио</Checkbox>
                <Checkbox name="q27_2">телевидение</Checkbox>
                <Checkbox name="q27_3">печатные издания</Checkbox>
                <Checkbox name="q27_4">интернет</Checkbox>
                <Checkbox name="q27_5">от врача</Checkbox>
                <Checkbox name="q27_6">от знакомых</Checkbox>
                <Input name="q27_7" placeholder="др."/>
            </Field>                
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
}

GeneralInfoAnamnesisForm.defaultProps = {
    initialData: {  
        date: todayDate(),
        q1:[],
        q3:[],
        q4:[],
        q5:[],
        q6:[],
        q7:[],
        q8:[],
        q10:[],
        q11:[],
        q12:[],
        q14: 1,
        q15: 1,
        q16: 1,
        q18: 1,
        q19: 1,
        q22: 1,
        q21: 1,
        q23: 1,
        q24: 1,
    },
};