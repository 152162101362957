import { h, Fragment } from 'preact';
import { useEffect, useState, useRef, useCallback } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';
import { useField } from '../unform';
import { useCurrentState, useClickAway } from '../hooks';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput, ButtonGroup } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    date: Joi.date(),
    person: Joi.string(),
    q1: Joi.number(),
    q2: Joi.string().allow(''),
    indSymptoms: Joi.array(),
    q4: Joi.array(),
    recommendations: Joi.array(),
    q5: Joi.boolean(),
    q6: Joi.boolean(),
    q7: Joi.boolean(),
    q8: Joi.boolean(),
    q9: Joi.string().allow(''),
    q10: Joi.string().allow(''),
});

const RecommendationsList = ({name}) => {

    const { fieldName, defaultValue, registerField } = useField(name);
    const [state, setState, stateRef] = useCurrentState(defaultValue);
    const [recommendations, setRecommendations] = useState([]);
    const [, redraw] = useState([]);

    useEffect(() => {
        api.get('recommendations')
            .json()
            .then((list) => {
                setRecommendations(list);
            });
    }, [])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: stateRef,
            getValue: ref => stateRef.current,
            setValue: (ref, value) => {
                setState(value);
                console.log(state);
            },
            clearValue: ref => {
                setState([]);
            },
        });
    }, [stateRef, fieldName, registerField]);

    const setDoctor =  (e, ind) => {
        const buf = state;
        buf[ind].doctor = parseInt(e.target.value);
        setState(buf);
    }

    const setAdditional = (e, ind) => {
        const buf = state;
        buf[ind].additional = e.target.value;
        setState(buf);
    }

    const addRecommendation = useCallback(() => {
        const buf = state;
        buf.push({
            doctor: 0,
            rec: [],
            additional: ''
        })
        setState(buf);
        redraw([]);
    }, [state]);

    const removeRecommendation =  useCallback((ind) => {
        const buf = state.filter((val, i) => i != ind);
        setState([]);
        setState(buf);
        console.log(buf);
        console.log(state);
        redraw([]);
    }, [state]);

    const setRecs = (val, ind) => {
        const buf = state;
        buf[ind].rec = val;
        setState(buf);
        
    }

    return <>
        {
            state.map((el, ind) => {
                return  <>
                    <Form initialData={el} >
                        <Field horizontal>
                            <Select name='doctor' onChange = {(el)=> setDoctor(el, ind)}>
                                <option value={0}> Ткачёва Н.И. </option>
                                <option value={1}> Сысолякин И.Е. </option>
                                <option value={2}> Белкина И.Н. </option>
                            </Select>
                            <TagsInput
                                name = 'rec'
                                defaultItems = {recommendations} 
                                onChange = {((v) => setRecs(v, ind))}
                                fontSz = 'x-small'
                                itemToTag={item => item.name}
                                itemToLabel={item => `${item.name}`}
                                itemToId={item => item.id}
                            />
                            <Input name='additional' type="text" onChange = {(el) => setAdditional(el, ind)}></Input>
                            <Button type = 'button'
                                color = 'danger'
                                size = 'small'
                                onClick = {() => {removeRecommendation(ind)}}>
                                -
                            </Button>
                        </Field>
                    </Form>
                </>
            })
        }
        <ButtonGroup align="right">
            <Button type = 'button' 
                color = 'success' 
                size = 'small' 
                onClick={() => addRecommendation()}>
                    добавить +
            </Button>
        </ButtonGroup>
    </>

}

export const FinalHealthStatusForm = ({checkupId, doctors, initialData}) => {

    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [expandedFields, setExpandedFields] = useState([]);
    const [risks, setRisks] = useState([]);
    const [indSymptoms, setIndSymptoms] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [recValue, setRecValue] = useState([]);

    initialData.date = formatDateToYMD(initialData.date);

    const handleSubmit = async (data, { reset }) => {
        try {
            console.log(data);
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/46`, { json: result }).json();
            await api.post(`centralhealth/healthgroup/${checkupId}`, {json: {healthGroup: data.q1}})
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }   
    }

    useEffect(() => {
        api.get('risks/1')
            .json()
            .then((list) => {
                setRisks(list);
            });
        api.get('risks/2')
            .json()
            .then((list) => {
                setIndSymptoms(list);
            });
        api.get('recommendations')
            .json()
            .then((list) => {
                setRecommendations(list);
            });
    }, [])

    if (saved) {
        return 'Сохранено';
    }

    const expandField = (name, expandedVal) => val => {
        if (val === expandedVal) {
            setExpandedFields(prev => [...prev, name]);
        } else {
            setExpandedFields(prev => prev.filter(field => name !== field));
        }
    };


    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
           <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field label = "Состояние здоровья" horizontal>
                <Select name = "q1">
                    <option value={1}>I группа</option>
                    <option value={2}>II группа</option>
                    <option value={3}>III группа</option>
                </Select>
                <Field>
                    <TagsInput
                        name = 'indSymptoms'
                        defaultItems = {indSymptoms} 
                        onChange = {(() => indSymptoms)}
                        placeholder = "Функциональные расстройства"
                        itemToTag={item => item.name}
                        itemToLabel={item => `${item.name}`}
                        itemToId={item => item.id}
                    />
                </Field>
            </Field>
            <Field label='Факторы риска' horizontal>
                <TagsInput
                    name = 'q4'
                    defaultItems = {risks} 
                    onChange = {(() => risks)}
                    itemToTag={item => item.name}
                    itemToLabel={item => `${item.name}`}
                    itemToId={item => item.id}
                />
            </Field>
            <Field label = "Рекомендации">
                <RecommendationsList name = 'recommendations'/>
            </Field>
            <Field label="Школы здоровья">
                <Field horizontal>
                    <Checkbox name = 'q5'>Школа профилактики артериальной гипертензии</Checkbox>
                </Field>
                <Field horizontal>
                    <Checkbox name = 'q6'>Школа профилактики заболеваний суставов и позвоночника</Checkbox>
                </Field>
                <Field horizontal>
                    <Checkbox name = 'q7'>Школа профилактики заболеваний бронхиальной астмы</Checkbox>
                </Field>
                <Field horizontal>
                    <Checkbox name = 'q8'>Школа профилактики заболеваний сахарного диабета</Checkbox>
                </Field>
                <Field horizontal>
                    <Input name = 'q9' placeholder='прочие школы'/>
                </Field>
            </Field>
            <Field horizontal label='Посещение кабинета (зала) ЛФК'>
                <Input name = 'q10'/>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    )

}

FinalHealthStatusForm.defaultProps = {
    initialData: {  
        date: todayDate(),
        indSymptoms: [],
        q4: [],
        recommendations: [],
    }
}