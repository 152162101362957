import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    person: Joi.string(),
    date: Joi.date(),
    q1: Joi.number(),
    q2: Joi.number(),
    q3: Joi.number(),
    q4: Joi.string().allow(''),
    q5: Joi.string().allow(''),
    q6: Joi.string().allow(''),
    q7: Joi.number(),
    q8: Joi.date().allow(''),
});

export const GeneralInfoForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});

    initialData.date = formatDateToYMD(initialData.date);
    initialData.q8 = formatDateToYMD(initialData.q8);

    if (saved) {
        return 'Сохранено';
    }

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/39`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
           <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field label="№ поликлиники по месту жительства" horizontal>
                <Input name="q1" type='number'></Input>
            </Field>
            <Field label="Живет постоянно" horizontal>
                <Field>
                    <RadioGroup name="q2">
                        <Radio value={1}> город </Radio>
                        <Radio value={2}> село </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field label="Социальное положение" horizontal>
                <RadioGroup name="q3">
                    <Radio value={1}> Служащий </Radio>
                    <Radio value={2}> Рабочий </Radio>
                    <Radio value={3}> Учащийся </Radio>
                    <Radio value={4}> Неработающий </Radio>
                </RadioGroup>                
            </Field>
            <Field label="Образование" horizontal>
                <Input name="q4"></Input>
            </Field>
            <Field label="Место работы" horizontal>
                <Input name="q5"></Input>
            </Field>
            <Field label="Профессия, должность" horizontal>
                <Input name="q6"></Input>
            </Field>
            <Field horizontal>
                <Field>
                    <RadioGroup name = "q7">
                        <Radio value = {1}>Обратился самостоятельно</Radio>
                        <Radio value = {2}>Направлен амбулаторно-поликлиническим учреждением</Radio>
                        <Radio value = {3}>Направлен после лечения в стационаре</Radio>
                        <Radio value = {4}>Направлен работодателем после прохождения ПМО и УМО</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Дата обращения/направления">
                <Input name="q8" type="date"></Input>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
}

GeneralInfoForm.defaultProps = {
    initialData: {  
        date: todayDate(),
        q2: 1,
        q3: 1,
        q7: 1,
        q8: todayDate(),
    },
};