import { h } from 'preact';
import { forwardRef, useState, useRef, useCallback, useImperativeHandle } from 'preact/compat';
import dlv from 'dlv';
import { dset } from 'dset';
import { flattie } from 'flattie';
import { nestie } from 'nestie';

import { FormContext } from './context';

const Form = ({ initialData = {}, children, onSubmit }, formRef) => {
    const [errors, setErrors] = useState({});
    const fields = useRef([]);

    const getFieldByName = useCallback(
        fieldName => fields.current.find(unformField => unformField.name === fieldName),
        []
    );

    const getFieldValue = useCallback(({ ref, getValue, path }) => {
        if (getValue) return getValue(ref);
        return path && dlv(ref, path);
    }, []);

    const setFieldValue = useCallback(({ path, ref, setValue }, value) => {
        if (setValue) return setValue(ref, value);
        return path ? dset(ref, path, value) : false;
    }, []);

    const clearFieldValue = useCallback(({ clearValue, ref, path }) => {
        if (clearValue) return clearValue(ref, '');
        return path && dset(ref, path, '');
    }, []);

    const reset = useCallback((data = {}) => {
        fields.current.forEach(({ name, ref, path, clearValue }) => {
            if (clearValue) return clearValue(ref, data[name]);
            return path && dset(ref, path, data[name] ? data[name] : '');
        });
    }, []);

    const setData = useCallback(
        data => {
            const fieldValue = {};

            fields.current.forEach(field => {
                fieldValue[field.name] = dlv(data, field.name);
            });

            Object.entries(fieldValue).forEach(([fieldName, value]) => {
                const field = getFieldByName(fieldName);
                if (field) setFieldValue(field, value);
            });
        },
        [getFieldByName, setFieldValue]
    );

    const setFormErrors = useCallback(formErrors => {
        const parsedErrors = flattie(formErrors);
        setErrors(parsedErrors);
    }, []);

    const parseFormData = useCallback(() => {
        const data = {};

        fields.current.forEach(field => {
            data[field.name] = getFieldValue(field);
        });

        return nestie(data);
    }, [getFieldValue]);

    const handleSubmit = useCallback(
        async event => {
            if (event) event.preventDefault();
            const data = parseFormData();
            onSubmit(data, { reset }, event);
        },
        [onSubmit, parseFormData, reset]
    );

    const registerField = useCallback(field => {
        console.log('register', field);
        fields.current.push(field);
    }, []);

    const unregisterField = useCallback(fieldName => {
        const fieldIndex = fields.current.findIndex(field => field.name === fieldName);

        if (fieldIndex > -1) fields.current.splice(fieldIndex, 1);
    }, []);

    const clearFieldError = useCallback(fieldName => {
        setErrors(state => ({ ...state, [fieldName]: undefined }));
    }, []);

    useImperativeHandle(formRef, () => ({
        getFieldValue(fieldName) {
            const field = getFieldByName(fieldName);
            return !field ? false : getFieldValue(field);
        },
        setFieldValue(fieldName, value) {
            const field = getFieldByName(fieldName);
            return !field ? false : setFieldValue(field, value);
        },
        getFieldError(fieldName) {
            return errors[fieldName];
        },
        setFieldError(fieldName, error) {
            setErrors(state => ({ ...state, [fieldName]: error }));
        },
        clearField(fieldName) {
            const field = getFieldByName(fieldName);
            if (field) clearFieldValue(field);
        },
        getErrors() {
            return errors;
        },
        setErrors(formErrors) {
            return setFormErrors(formErrors);
        },
        getData() {
            return parseFormData();
        },
        getFieldRef(fieldName) {
            const field = getFieldByName(fieldName);
            return !field ? false : field.ref;
        },
        setData(data) {
            return setData(data);
        },
        reset(data) {
            return reset(data);
        },
        submitForm() {
            handleSubmit();
        },
    }));

    return (
        <FormContext.Provider
            value={{
                initialData,
                errors,
                scopePath: '',
                registerField,
                unregisterField,
                clearFieldError,
                handleSubmit,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const FormProvider = forwardRef(Form);
