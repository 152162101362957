import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';
import { useNavbarContext } from './NavbarContext';

export const NavbarMenu = props => {
    const { isActive } = useNavbarContext();

    return <Element className={clsx('navbar-menu', isActive && 'is-active')} {...props} />;
};
