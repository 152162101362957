import { h, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useLocation } from 'wouter-preact';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { Container, Box, Table, Row, Column, Title, Field, Button, Input } from '../components';
import { PatientsQueue } from '../components/PatientsQueue';
import { api, getAge, todayDate } from '../helpers';
import { CheckIcon, HourglassIcon, XIcon } from '../icons';
import Form from '../unform';
import CheckupsTable from '../forms/CheckupsTable';
import PatientProfile from './PatientProfile';


function Patients() {
    const [, setLocation] = useLocation();
    const [patient, setPatient] = useState(null);
    const [checkup, setCheckup] = useState(null);
    const [patientPlan, setPatientPlan] = useState([]);
    const [centralHealth, setCentralHealth] = useState([]);
    const [loading, setLoading] = useState(false);
    const [closed, setClosed] = useState(false);

    const onPatientSelect = async pat => {
        setPatient(null);
        setClosed(false);
        setLoading(false);
        const plan = await api.get(`checkups/${pat.checkupId}/services`).json();
        const check = await api.get(`checkups/${pat.checkupId}`).json();
        console.log(check);
        if(check.endDate) setClosed(true);
        setPatient(pat);
        setCheckup(check);
        
        const ch = plan.filter(val => {
            if(val.serviceId >= 43 && val.serviceId <= 47) return val;
        })
        setCentralHealth(ch);
        setPatientPlan(plan.filter(val => {if(!(val.serviceId >= 43 && val.serviceId <= 47)) return val;}));
    };


    const handleSubmit = async (data, { reset }) => {
        setLoading(true);
        try {
            await api.post(`checkups/${patient.checkupId}/close`, { json: data})
            setClosed(true);
        } catch(e) {
            console.log(e);
        }

        setLoading(false);
    }


    return (
        <Container>
            <Row>
                <Column fullhd>
                    {patient && checkup && centralHealth ? (
                        <>

                            <Title>
                                {patient.fullName} {dayjs(patient.dateOfBirth).format('DD.MM.YYYY')}
                                {' '}
                                <h1>Случай от {dayjs(checkup.startDate).format('DD.MM.YYYY')}</h1>
                            </Title>                            

                            <Row>
                                <Form onSubmit={handleSubmit}>  
                                    <Field horizontal>
                                        <Column> <Input type="date" name="date" value={todayDate()} disabled={closed}/> </Column>
                                        <Column> <Button color="danger" loading={loading} disabled={closed}>{!closed?"Закрыть случай":"Закрыт"}</Button> </Column>
                                    </Field>
                                </Form>
                            </Row>
                            <PatientProfile patientId={patient.id} checkupID={patient.checkupId}></PatientProfile>
                        </>
                    ) : (
                        'Пациент не выбран'
                    )}
                </Column>

            </Row>
            <Row>
                <Column span={8}></Column>
                <Column span={4}>
                        <PatientsQueue onPatientSelect={onPatientSelect} />
                </Column>
            </Row>
        </Container>
    );
}

export default Patients;
