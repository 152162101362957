import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Tile = ({ kind, vertical, size, color, ...props }) => (
    <Element
        className={clsx('tile', {
            [`is-${kind}`]: kind,
            [`is-${size}`]: size,
            [`is-${color}`]: color,
            'is-vertical': vertical,
        })}
        {...props}
    />
);
