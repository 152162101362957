import { h } from 'preact';
import { useState, useMemo } from 'preact/hooks';
import Joi from 'joi';

import { Field, Button, Input } from '../components';
import { api } from '../helpers';
import Form from '../unform';

const schema = Joi.object({
    height: Joi.number().integer().min(100).max(300),
    weight: Joi.number().integer().min(30).max(500),
    imt: Joi.number(),
    ot: Joi.number(),
    chss: Joi.number().integer().min(40).max(200),
    ad_upper: Joi.number().integer().min(90).max(200),
    ad_lower: Joi.number().integer().min(50).max(150),
    chdd: Joi.number().integer().min(16).max(40),
    sat: Joi.number().integer().min(0).max(99),
});

export const AnthropometryForm = ({ checkupId, initialData }) => {
    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);

    const imt = useMemo(() => weight / Math.pow(height / 100, 2), [weight, height]);
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });

            const resp = await api.post(`measurements/${checkupId}/3`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field horizontal label="Рост">
                <Field error={errors.height}>
                    <Input name="height" onInput={ev => setHeight(ev.target.value)} />
                </Field>
            </Field>
            <Field horizontal label="Вес">
                <Field error={errors.weight}>
                    <Input name="weight" onInput={ev => setWeight(ev.target.value)} />
                </Field>
            </Field>
            <Field horizontal label="ИМТ">
                <Field error={errors.imt}>
                    <Input name="imt" disabled value={imt || null} />
                </Field>
            </Field>
            <Field horizontal label="ОТ">
                <Field error={errors.ot}>
                    <Input name="ot" placeholder="объём талии" />
                </Field>
            </Field>
            <Field horizontal label="ЧСС">
                <Field error={errors.chss}>
                    <Input name="chss" placeholder="40-200" />
                </Field>
            </Field>
            <Field horizontal label="АД">
                <div className="field has-addons">
                    <p className="control is-expanded">
                        <Input name="ad_upper" placeholder="90-200" />
                    </p>
                    <p className="control is-expanded">
                        <Input name="ad_lower" placeholder="50-150" />
                    </p>
                </div>
                {(errors.ad_upper || errors.ad_lower) && (
                    <p className="help is-danger">{errors.ad_upper || errors.ad_lower}</p>
                )}
            </Field>
            <Field horizontal label="ЧДД">
                <Field error={errors.chdd}>
                    <Input name="chdd" placeholder="16-40" />
                </Field>
            </Field>
            <Field horizontal label="Sat O₂">
                <Field error={errors.sat}>
                    <Input name="sat" placeholder="70-99" />
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
};
