import { h } from 'preact';

import { Element } from './Element';

export const Radio = ({ children, value, disabled, checked, onChange, ...props }) => (
    <Element tag="label" className="radio" disabled={disabled} {...props}>
        <input type="radio" checked={checked} disabled={disabled} onChange={() => onChange(value)} />
        {children}
    </Element>
);
