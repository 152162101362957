import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';
import { formatDateToYMD } from '../helpers';

const schema = Joi.object({
    adimt: Joi.bool(),
    date: Joi.date(),
    doctor: Joi.string(),
    glsmoking: Joi.bool(),
    nfa: Joi.bool(),
    oheating: Joi.bool(),
    q1: Joi.string().allow(),
    q2: Joi.string().allow(),
    q3: Joi.string().allow(),
    q4: Joi.string().allow(),
    q5: Joi.string().allow(),
    q6: Joi.string().allow(),
    q7: Joi.string().allow(),
    q8_1: Joi.string(),
    q8_2: Joi.string(),
    q9_1: Joi.string(),
    q9_2: Joi.bool(),
    q9_3: Joi.bool(),
    q9_4: Joi.bool(),
    q10_1: Joi.string(),
    q10_2: Joi.bool(),
    q10_3: Joi.bool(),
    q11_1: Joi.string(),
    q11_2: Joi.bool(),
    q12_1: Joi.string(),
    q12_2: Joi.bool(),
    q12_3: Joi.bool(),
    q13_1: Joi.string(),
    q13_2: Joi.bool(),
    q13_3: Joi.bool(),
    q13_4: Joi.bool(),
    q14_1: Joi.string(),
    q14_2: Joi.bool(),
    q14_3: Joi.bool(),
    q15_1: Joi.string(),
    q15_2: Joi.bool(),
    q15_3: Joi.bool(),
    q16_1: Joi.string(),
    q16_2: Joi.bool(),
    q17_1: Joi.string(),
    q17_2: Joi.bool(),
    q18_1: Joi.string(),
    q18_2: Joi.bool(),
    q18_3: Joi.bool(),
    q18_4: Joi.bool(),
    q18_5: Joi.bool(),
    q19_1: Joi.string(),
    q19_2: Joi.bool(),
    q20_1: Joi.string(),
    q20_2: Joi.bool(),
    q20_3: Joi.bool(),
    q20_4: Joi.bool(),
    q21_1: Joi.string(),
    q21_2: Joi.bool(),
    q21_3: Joi.bool(),
    q21_4: Joi.bool(),
    q22_1: Joi.string(),
    q22_2: Joi.bool(),
    q23_1: Joi.string(),
    q23_2: Joi.bool(),
    q23_3: Joi.bool(),
    q24: Joi.array(),
    q25: Joi.string(),
    q26: Joi.string().allow(),
    rppa: Joi.bool(),
});

export const TherapistForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [hints, setHints] = useState(null);
    const [expandedFields, setExpandedFields] = useState([]);

    initialData.date = formatDateToYMD(initialData.date);

    const handleSubmit = async (data, { reset }) => {
        try {
            console.log(data);

            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/14`, { json: result }).json();

            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    const searchDisease = async str => {
        const items = await api.get('diseases', { searchParams: { query: str } }).json();
        return items;
    };

    const expandField = (name, expandedVal) => val => {
        if (val === expandedVal) {
            setExpandedFields(prev => [...prev, name]);
        } else {
            setExpandedFields(prev => prev.filter(field => name !== field));
        }
    };

    useEffect(() => {
        api.get(`checkups/${checkupId}/hints`)
            .json()
            .then(res => setHints(res));
    }, [checkupId]);

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field horizontal label="Дата">
                <Field>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field horizontal label="Врач">
                <Field>
                    <Select name="doctor">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field horizontal label="Жалобы">
                <Field>
                    <Input name="q1" />
                </Field>
            </Field>
            <Field horizontal label="Анамнез заболевания">
                <Field>
                    <Input name="q2" />
                </Field>
            </Field>
            <Field horizontal label="Перенесенные заболевания">
                <Field>
                    <Input name="q3" />
                </Field>
            </Field>
            <Field horizontal label="Травмы, операции">
                <Field>
                    <Input name="q4" />
                </Field>
            </Field>
            <Field horizontal label="Наследственность">
                <Field>
                    <Input name="q5" />
                </Field>
            </Field>
            <Field horizontal label="Алкоголь">
                <Field>
                    <Input name="q6" />
                </Field>
            </Field>
            <Field horizontal label="Курение">
                <Field>
                    <Input name="q7" />
                </Field>
            </Field>
            <Field horizontal label="Телосложение">
                <Field>
                    <RadioGroup name="q8_1" onChange={expandField('q8_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q8_1') })}>
                    <RadioGroup name="q8_2">
                        <Radio value="1"> астеническое</Radio>
                        <Radio value="2"> гиперстеническое</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Кож. покровы">
                <Field>
                    <RadioGroup name="q9_1" onChange={expandField('q9_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q9_1') })}>
                    <Checkbox name="q9_2"> влажные</Checkbox>
                    <Checkbox name="q9_3"> сухие</Checkbox>
                    <Checkbox name="q9_4"> высыпания</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Периф. л/узлы">
                <Field>
                    <RadioGroup name="q10_1" onChange={expandField('q10_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q10_1') })}>
                    <Checkbox name="q10_2"> увеличены</Checkbox>
                    <Checkbox name="q10_3"> болезненные</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="ЩЖ">
                <Field>
                    <RadioGroup name="q11_1" onChange={expandField('q11_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q11_1') })}>
                    <Checkbox name="q11_2"> увеличена</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Зев">
                <Field>
                    <RadioGroup name="q12_1" onChange={expandField('q12_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q12_1') })}>
                    <Checkbox name="q12_2"> гиперемия дужек</Checkbox>
                    <Checkbox name="q12_3"> гиперемия задней стенки глотки</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Миндалины">
                <Field>
                    <RadioGroup name="q13_1" onChange={expandField('q13_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q13_1') })}>
                    <Checkbox name="q13_2"> гиперемированы</Checkbox>
                    <Checkbox name="q13_3"> увеличены</Checkbox>
                    <Checkbox name="q13_4"> налёты</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Дыхание">
                <Field>
                    <RadioGroup name="q14_1" onChange={expandField('q14_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q14_1') })}>
                    <Checkbox name="q14_2"> жёсткое</Checkbox>
                    <Checkbox name="q14_3"> ослаблено</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Тоны сердца">
                <Field>
                    <RadioGroup name="q15_1" onChange={expandField('q15_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q15_1') })}>
                    <Checkbox name="q15_2"> приглушённые</Checkbox>
                    <Checkbox name="q15_3"> глухие</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Ритм">
                <Field>
                    <RadioGroup name="q16_1" onChange={expandField('q16_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q16_1') })}>
                    <Checkbox name="q16_2"> аритмичный</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Шумы">
                <Field>
                    <RadioGroup name="q17_1" onChange={expandField('q17_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q17_1') })}>
                    <Checkbox name="q17_2"> есть</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Живот">
                <Field>
                    <RadioGroup name="q18_1" onChange={expandField('q18_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q18_1') })}>
                    <Checkbox name="q18_2"> боль в эпигастрии</Checkbox>
                    <Checkbox name="q18_3"> в Л подреберье</Checkbox>
                    <Checkbox name="q18_4"> в П подреберье</Checkbox>
                    <Checkbox name="q18_5"> по ходу кишечника</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Печень">
                <Field>
                    <RadioGroup name="q19_1" onChange={expandField('q19_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q19_1') })}>
                    <Checkbox name="q19_2"> увеличена</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Стул">
                <Field>
                    <RadioGroup name="q20_1" onChange={expandField('q20_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q20_1') })}>
                    <Checkbox name="q20_2"> ежедневный</Checkbox>
                    <Checkbox name="q20_3"> оформленный</Checkbox>
                    <Checkbox name="q20_4"> склонность к запорам</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Симптом поколачивания">
                <Field>
                    <RadioGroup name="q21_1" onChange={expandField('q21_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q21_1') })}>
                    <Checkbox name="q21_2"> положительный слева</Checkbox>
                    <Checkbox name="q21_3"> положительный справа</Checkbox>
                    <Checkbox name="q21_4"> положительный с обеих сторон</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Отёки">
                <Field>
                    <RadioGroup name="q22_1" onChange={expandField('q22_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q22_1') })}>
                    <Checkbox name="q22_2"> есть</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Мочеиспускание">
                <Field>
                    <RadioGroup name="q23_1" onChange={expandField('q23_1', '2')}>
                        <Radio value="1"> норма</Radio>
                        <Radio value="2"> не норма</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q23_1') })}>
                    <Checkbox name="q23_2"> учащённое</Checkbox>
                    <Checkbox name="q23_3"> болезненное</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Диагноз (шифр по МКБ)">
                <Field
                    caption={
                        hints &&
                        hints.diseases.length > 0 &&
                        'Рекомендуются: ' + hints.diseases.map(d => d.code).join('; ')
                    }
                >
                    <TagsInput
                        name="q24"
                        placeholder="МКБ-10"
                        onSearch={searchDisease}
                        itemToTag={item => item.code}
                        itemToLabel={item => `${item.code} ${item.name}`}
                        itemToId={item => item.id}
                    />
                </Field>
            </Field>
            <Field horizontal label="Группа здоровья">
                <Field>
                    <RadioGroup name="q25">
                        <Radio value="1"> 1</Radio>
                        <Radio value="2"> 2</Radio>
                        <Radio value="3"> 3А</Radio>
                        <Radio value="4"> 3Б</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Факторы риска">
                <Field
                    caption={
                        hints && hints.risks.length > 0 && 'Рекомендуются: ' + hints.risks.map(r => r.name).join('; ')
                    }
                >
                    <Checkbox name="adimt"> АД, ИМТ</Checkbox>
                    <Checkbox name="oheating"> ОХ, питание</Checkbox>
                    <Checkbox name="glsmoking"> ГЛ, курение</Checkbox>
                    <Checkbox name="rppa"> РППА</Checkbox>
                    <Checkbox name="nfa"> НФА</Checkbox>
                </Field>
            </Field>
            <Field horizontal label="Рекомендации">
                <Field>
                    <Textarea name="q26" />
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
};

TherapistForm.defaultProps = {
    initialData: {
        q8_1: '1',
        q9_1: '1',
        q10_1: '1',
        q11_1: '1',
        q12_1: '1',
        q13_1: '1',
        q14_1: '1',
        q15_1: '1',
        q16_1: '1',
        q17_1: '1',
        q18_1: '1',
        q19_1: '1',
        q20_1: '1',
        q21_1: '1',
        q22_1: '1',
        q23_1: '1',
        q24: [],
    },
};
