import { h } from 'preact';

import { Container, Title } from '../components';

function NotFound() {
    return (
        <Container breakpoint="fluid">
            <Title size={4}>Страница не найдена</Title>
        </Container>
    );
}

export default NotFound;
