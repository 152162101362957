import { h } from 'preact';
import { Redirect } from 'wouter-preact';

import { Box, Column, Container, Field, Hero, Row, Button, Input } from '../components';
import { useAuth } from '../context/auth-context';
import Form from '../unform';

function Login() {
    const { user, login, error } = useAuth();

    if (user) {
        const params = new URLSearchParams(location.search);
        const returnTo = params.get('returnTo');
        return <Redirect to={returnTo || '/'} />;
    }

    return (
        <Hero size="fullheight">
            <Hero.Body>
                <Container>
                    <Row multiline centered>
                        <Column narrow>
                            <Box>
                                <Form onSubmit={login}>
                                    <Field label="Логин">
                                        <Input name="login" />
                                    </Field>
                                    <Field label="Пароль">
                                        <Input name="password" type="password" />
                                    </Field>
                                    <Field error={error}>
                                        <Button fullwidth color="primary">
                                            Войти
                                        </Button>
                                    </Field>
                                </Form>
                            </Box>
                        </Column>
                    </Row>
                </Container>
            </Hero.Body>
        </Hero>
    );
}

export default Login;
