import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const PanelBlock = ({ active, ...props }) => (
    <Element
        className={clsx('panel-block', {
            'is-active': active,
        })}
        {...props}
    />
);
