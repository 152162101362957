import { h } from 'preact';
import { useContext } from 'preact/hooks';

import { FormContext } from './context';

export function Scope({ path, children }) {
    const { scopePath, ...form } = useContext(FormContext);

    return (
        <FormContext.Provider
            value={{
                ...form,
                scopePath: scopePath.concat(scopePath ? `.${path}` : path),
            }}
        >
            {children}
        </FormContext.Provider>
    );
}
