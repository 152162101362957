import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    person: Joi.string(),
    date: Joi.date(),
    q1_1: Joi.string(),
    q1_2: Joi.string().allow(''),
    VOD1: Joi.number(),
    VOD2: Joi.number(),
    VOD3: Joi.number(),
    VOD4: Joi.number(),
    VOZ1: Joi.number(),
    VOZ2: Joi.number(),
    VOZ3: Joi.number(),
    VOZ4: Joi.number(),
    tonometry1: Joi.number(),
    tonometry2: Joi.number(),
    q2: Joi.array()
});

export const OphthalmologistForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [expandedFields, setExpandedFields] = useState([]);
    const [hints, setHints] = useState(null);
    const [deseases, setDeseases] = useState([])

    initialData.date = formatDateToYMD(initialData.date);

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/44`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    useEffect(async() => {
        await api.get(`chdeseases/ophtalmologist-deseases`)
            .json()
            .then(res => setDeseases(res));
        console.log(deseases);
    }, [])

    const searchDisease = async str => {
        return deseases;
    };

    const expandField = (name, expandedVal) => val => {
        if (val === expandedVal) {
            setExpandedFields(prev => [...prev, name]);
        } else {
            setExpandedFields(prev => prev.filter(field => name !== field));
        }
    };

    if (saved) {
        return 'Сохранено';
    }

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
          <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field horizontal label="Жалобы">
                <Field>
                    <RadioGroup name="q1_1" onChange={expandField('q1_1', '2')}>
                        <Radio value="1"> нет </Radio>
                        <Radio value="2"> есть </Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal>
                <Field className={clsx('details', { 'is-active': expandedFields.includes('q1_1') })}>
                    <Input type="text" name="q1_2"></Input>
                </Field>
            </Field>
            <Field label="Острота зрения: ">
                <Field label="VOD" horizontal>
                    <Input type="number" name="VOD1" step="0.01" />
                    <RadioGroup name="VOD2">
                        <span>sph</span>
                        <Radio value={1}>+</Radio>
                        <Radio value={2}>-</Radio>
                    </RadioGroup>
                    <Input type="number" name="VOD3" step="0.01"/>
                    <Input type="number" name="VOD4" step="0.01" />
                </Field>
                <Field label="VOZ" horizontal>
                <Input type="number" name="VOZ1" step="0.01" />
                    <RadioGroup name="VOZ2">
                        <span>sph</span>
                        <Radio value={1}>+</Radio>
                        <Radio value={2}>-</Radio>
                    </RadioGroup>
                    <Input type="number" name="VOZ3" step="0.01"/>
                    <Input type="number" name="VOZ4" step="0.01" />
                </Field>
            </Field>
            <Field label="Тонометрия" horizontal>
                <Input type="number" name="tonometry1" step="0.01" />
                <span>/</span>
                <Input type="number" name="tonometry2" step="0.01" />
            </Field>
            <Field horizontal label="Диагноз (шифр по МКБ)">
                <Field>
                    <TagsInput
                        name="q2"
                        placeholder="МКБ-10"
                        onSearch={searchDisease}
                        defaultItems={deseases}
                        itemToTag={item => item.name}
                        itemToLabel={item => `${item.name}`}
                        itemToId={item => item.id}
                    />
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>)

}

OphthalmologistForm.defaultProps = {
    initialData: {
        date: todayDate(),
        q1_1: '1',
        q2: [],
    }
}