import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Container = ({ breakpoint, max, ...props }) => {
    const canSetMax = ['desktop', 'widescreen'].includes(breakpoint);

    return (
        <Element
            className={clsx('container', breakpoint && `is-${canSetMax && max ? 'max-' : ''}${breakpoint}`)}
            {...props}
        />
    );
};
