import { h } from 'preact';
import { useRef, useEffect, useState, useMemo } from 'preact/hooks';
import { useClickAway } from '../hooks';

import { useField } from '../unform';

export const Autocomplete = ({ name, placeholder, disabled, children, onSearch, onChange, ...props }) => {
    const inputRef = useRef();
    const controlRef = useRef();
    const { fieldName, defaultValue, registerField } = useField(name);
    const [items, setItems] = useState([]);
    const [focus, setFocus] = useState(false);
    const isOpen = useMemo(() => items.length > 0 && focus, [items, focus]);

    useClickAway(controlRef, () => setFocus(false));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value;
            },
            clearValue: ref => {
                ref.current.value = '';
            },
        });
    }, [fieldName, registerField]);

    const handleInput = async ev => {
        if (!onSearch) return;
        const newItems = await onSearch(ev.target.value);
        setItems(newItems);
    };

    const handleClick = item => () => {
        setFocus(false);
        onChange && onChange(item);
    };

    return (
        <div ref={controlRef} className="control is-expanded">
            <div className="control">
                <input
                    ref={inputRef}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    onInput={handleInput}
                    onFocus={() => setFocus(true)}
                />
            </div>
            {isOpen && (
                <div className="dropdown-menu is-block">
                    <div className="dropdown-content">
                        {items.map((item, index) => (
                            <a className="dropdown-item" key={index} onClick={handleClick(item)}>
                                {children({ index, item })}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
