import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { Container, Box, Table } from '../components';
import { api } from '../helpers';

function Services() {
    const [services, setServices] = useState([]);

    const getServices = async () => {
        const resp = await api.get('services').json();
        setServices(resp);
    };

    useEffect(() => {
        getServices();
    }, []);

    return (
        <Container>
            <Box>
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Тип</th>
                            <th>Название</th>
                            <th>Описание</th>
                        </tr>
                    </thead>
                    <tbody>
                        {services.map((service, index) => (
                            <Fragment key={service.id}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{service.type.name}</td>
                                    <td>{service.name}</td>
                                    <td>{service.description}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan={3}>
                                        <Table narrow>
                                            <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>Этап</th>
                                                    <th>Пол</th>
                                                    <th>Мин. возраст</th>
                                                    <th>Макс. возраст</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {service.planningRules.map((rule, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rule.phase}</td>
                                                        <td>{rule.sex}</td>
                                                        <td>{rule.minAge}</td>
                                                        <td>{rule.maxAge}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                    </tbody>
                </Table>
            </Box>
        </Container>
    );
}

export default Services;
