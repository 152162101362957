if (process.env.NODE_ENV !== 'production') {
    require('preact/debug');
}

import { h, render } from 'preact';

import { App } from './App';
import { AuthProvider } from './context/auth-context';

render(
    <AuthProvider>
        <App />
    </AuthProvider>,
    document.body
);
