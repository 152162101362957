import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput, Table } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form, { useField } from '../unform';
import { useCurrentState } from '../hooks';
import clsx from 'clsx';

const TableForm = ({columns = 0, rows = 0, columnHead = [], rowHead = [], name}) => {
    const { fieldName, defaultValue, registerField } = useField(name);
    const [state, setState, stateRef] = useCurrentState(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: stateRef,
            getValue: ref => stateRef.current,
            setValue: (ref, value) => {
                setState(value);
                console.log(state);
            },
            clearValue: ref => {
                setState([]);
            },
        });
    }, [stateRef, fieldName, registerField]);

    console.log(new Array(rows).fill(null));

    return <>
        <Table bordered>
            {columnHead.map((val,ind) => {
                return  <>
                    {ind == 0 && rowHead != []?<th/>:''}
                    <th>{val}</th>
                </>
            })}
            {(new Array(rows)).fill(null).map((v, ind1) => {
                return <>
                    <tr>
                        {(new Array(columns + (rowHead == []?0:1)).fill(null).map((val, ind2) => {
                        return <>
                            {ind2 == 0 && rowHead != []?
                                <th style={{fontSize: 'small'}}>{rowHead[ind1]}</th>:
                                <td style={{padding: '0px'}}> 
                                    <Form>
                                        <Input name = 't' style={{fontSize: 'small'}} />
                                    </Form>
                                </td>}
                        </>}))}
                    </tr>
                </>
            })}
        </Table>
    </>
}

export const DentistForm = ({ checkupId, doctors, initialData }) => {
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/47`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }   
    }

    if (saved) {
        return 'Сохранено';
    }
    
    return <>
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field label="Производил" horizontal>
                <Field error={errors.person}>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>
            <Field label="Факторы риска" horizontal>
                <Input name="risk1" placeholder = 'болезни сердца'></Input>
                <Input name="risk2" placeholder = 'гепатиты'></Input>
                <Input name="risk3" placeholder = 'диабет'></Input>
                <Input name="risk4" placeholder = 'гипертония'></Input>
                <Input name="risk5" placeholder = 'аллергия'></Input>
                <Input name="risk6" placeholder = 'венерич. болезни'></Input>
                <Input name="risk7" placeholder = 'астма'></Input>
                <Input name="risk8" placeholder = 'СПИД'></Input>
                <Input name="risk9" placeholder = 'кровотеч'></Input>
            </Field>               
            <Field label='Адрес' horizontal>
                <Field>
                    <Input name = 'address'></Input>            
                </Field>
            </Field>
            <Field label='Телефон' horizontal>
                <Field>
                    <Input name = 'phone' type='number'></Input>
                </Field>            
            </Field>
            <Field label='Профессия' horizontal>
                <Field>
                    <Input name = 'job'></Input>
                </Field>            
            </Field>
            <Field label='Жалобы' horizontal>
                <Field>
                    <Input name = 'complaints'></Input>
                </Field>            
            </Field>
            <Field label='Перенесенные и сопутствующие заболевания' horizontal>
                <Field>
                    <Input name = 'illnesses'></Input> 
                </Field>           
            </Field>
            Данные объективного исследования
            <Field label='Внешний осмотр' horizontal>
                <Field>
                    <Input name = 'externalInspection'></Input> 
                </Field> 
            </Field>
            Осмотр полости рта
            <TableForm name = 'tab1' rows = {6} columns = {16} rowHead={['эксудат', 'рецессия', 'пародонт. карман', 'подвижность', 'налет', 'состояние зубов']}/>
            <TableForm name = 'tab2' rows = {6} columns = {16} rowHead={['состояние зубов', 'налет', 'подвижность', 'пародонт. карман', 'рецессия', 'эксудат']}/>
            <Field horizontal>
                <Field label = 'КПУ (з)'>
                    <Input name='kpuz'></Input>
                </Field>
                <Field label = 'РМА'>
                    <Input name='pma'></Input>
                </Field>
            </Field>
            <Field horizontal>
                <Field label = 'КПУ(п)'>
                    <Input name = 'kpup'></Input>
                </Field>
                <Field label = 'ГИ'>
                    <Input name = 'gi'></Input>
                </Field>
                <Field label = 'CPI'>
                    <Input name = 'cpi'></Input>
                </Field>
            </Field>
            <Field label = 'Прикус' horizontal>
                <Input name = 'occlusion'></Input>
            </Field>
            <Field label = 'Состояние слизистой оболочки рта' horizontal>
                <Input name = 'mucosa'></Input>
            </Field>
            Дополнительные методы обследования
            <Field label = 'pH - ротовой жидкости (N-pH 6, 8-7)' horizontal>
                <Input name = 'ph'></Input>
            </Field>
            <Field label = 'Микробиологическое исследование' horizontal>
                <Input name = 'microbioInspection'></Input>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    </>
}

DentistForm.defaultProps = {
    initialData: {
        date: todayDate(),
    }
}