import { useEffect } from 'preact/hooks';

export function useClickAway(ref, handler) {
    useEffect(() => {
        const callback = ev => {
            const el = ref.current;
            if (!ev || !el || el.contains(ev.target)) return;
            handler(ev);
        };

        document.addEventListener('click', callback);
        return () => document.removeEventListener('click', callback);
    }, [ref, handler]);
}
