import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import dayjs from 'dayjs';

import { Container, Box, Row, Column, Title } from '../components';
import { AnthropometryForm } from '../forms/Anthropometry';
import { CBCForm } from '../forms/CBC';
import { ECGForm } from '../forms/ECG';
import { GlucoseLipoproteinForm } from '../forms/GlucoseLipoprotein';
import { GynecologistForm } from '../forms/Gynecologist';
import { Over65Form } from '../forms/Over65';
import { PSAForm } from '../forms/PSA';
import { StoolForm } from '../forms/Stool';
import { TherapistForm } from '../forms/Therapist';
import { Under65Form } from '../forms/Under65';
import { XrayForm } from '../forms/Xray';
import { api } from '../helpers';
import { OphthalmologistForm } from '../forms/Ophthalmologist';
import { GeneralInfoForm } from '../forms/GeneralInfo';
import { GeneralInfoAnamnesisForm } from '../forms/GeneralInfoAnamnesis';
import { SurveyResultsForm } from '../forms/SurveyResults';
import { FinalHealthStatusForm } from '../forms/FinalHealthResult';
import { DentistForm } from '../forms/Dentist';

const getFormByServiceId = id => {
    switch (id) {
        case 1:
            return Under65Form;
        case 2:
            return Over65Form;
        case 3:
            return AnthropometryForm;
        case 5:
            return GlucoseLipoproteinForm;
        case 10:
            return ECGForm;
        case 11:
            return XrayForm;
        case 12:
            return StoolForm;
        case 14:
            return TherapistForm;
        case 15:
            return PSAForm;
        case 17:
            return GynecologistForm;
        case 24:
            return OphthalmologistForm;
        case 29:
            return CBCForm;
        case 39:
            return GeneralInfoForm;
        case 40:
            return GeneralInfoAnamnesisForm;
        case 43:
            return SurveyResultsForm;
        case 44:
            return OphthalmologistForm;
        case 45:
            return GeneralInfoAnamnesisForm;
        case 46:
            return FinalHealthStatusForm;
        case 47:
            return DentistForm;
    }
};

const EditMeasurement = ({ measurementId }) => {
    const [measure, setMeasure] = useState(null);
    const [doctor, setDoctor] = useState([]);

    const getMeasurement = async () => {
        const resp = await api.get(`measurements/${measurementId}`).json();
        console.log('Edit measurement:', resp);
        setMeasure(resp);
    };

    const getDoctors = async () =>
    {
        await api.get('users')
        .json()
        .then(list => {
            setDoctor(list)
        });
    }

    useEffect(() => {
        getMeasurement();
        getDoctors();
    }, []);

    if (!measure) return 'Загрузка...';

    const props = {
        checkupId: measure.checkupId,
        initialData: measure.result,
        doctors: doctor,
    };

    const Form = getFormByServiceId(measure.serviceId);

    return (
        <Container>
            <Row centered>
                <Column span={8}>
                    <Title>Редактирование</Title>
                    <Box>
                        <Form {...props} />
                    </Box>
                </Column>
                <Column span={4}>Колонка</Column>
            </Row>
        </Container>
    );
};

export default EditMeasurement;
