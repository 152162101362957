import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';

import { useField } from '../unform';
import { Element } from './Element';

export const Checkbox = ({ name, children, value, disabled, ...props }) => {
    const inputRef = useRef();
    const { fieldName, defaultValue, registerField } = useField(name);

    const defaultChecked = defaultValue !== undefined?  defaultValue: value;

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.checked;
            },
            setValue: (ref, value) => {
                ref.current.checked = value;
            },
            clearValue: ref => {
                ref.current.checked = defaultChecked;
            },
        });
    }, [defaultChecked, fieldName, registerField]);

    return (
        <Element tag="label" className="checkbox" disabled={disabled} {...props}>
            <input type="checkbox" ref={inputRef} value={value} disabled={disabled} defaultChecked={defaultChecked} onChange={() => onChange(value)} />
            {children}
        </Element>
    );
};
