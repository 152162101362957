import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const Row = ({
    breakpoint,
    gap,
    multiline,
    centered,
    vCentered,
    mobile = {},
    tablet = {},
    desktop = {},
    widescreen = {},
    fullhd = {},
    touch = {},
    ...props
}) => (
    <Element
        className={clsx('columns', {
            [`is-${breakpoint}`]: breakpoint,
            [`is-${gap}`]: gap !== undefined,
            'is-multiline': multiline,
            'is-centered': centered,
            'is-vcentered': vCentered,
            'is-variable':
                gap !== undefined ||
                [touch, mobile, tablet, desktop, widescreen, fullhd].find(b => b.gap !== undefined),
            [`is-${touch.gap}-touch`]: touch.gap !== undefined,
            [`is-${mobile.gap}-mobile`]: mobile.gap !== undefined,
            [`is-${tablet.gap}-tablet`]: tablet.gap !== undefined,
            [`is-${desktop.gap}-desktop`]: desktop.gap !== undefined,
            [`is-${widescreen.gap}-widescreen`]: widescreen.gap !== undefined,
            [`is-${fullhd.gap}-fullhd`]: fullhd.gap !== undefined,
        })}
        {...{ mobile, tablet, desktop, widescreen, fullhd, touch }}
        {...props}
    />
);
