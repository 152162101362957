import { h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import Joi from 'joi';
import dayjs from 'dayjs';

import { Field, Button, Input, Select, Checkbox, RadioGroup, Radio, Textarea, TagsInput } from '../components';
import { api, formatDateToYMD, todayDate } from '../helpers';
import Form from '../unform';
import clsx from 'clsx';

const schema = Joi.object({
    person: Joi.string(),
    date: Joi.date(),
    height: Joi.number(),
    weight: Joi.number(),
    imt: Joi.number(),
    bloodPressure: Joi.string(),
    lipoprotein: Joi.number(),
    glucose: Joi.number(),
    FGEL: Joi.number(),
    FGELBR: Joi.number(),
    OFV1: Joi.number(),
    OFV1BR: Joi.number(),
    OFVFGEL: Joi.number(),
    OFVFGELBR: Joi.number(),
    COHB: Joi.number(),
    COHBBR: Joi.number(),
    psytest: Joi.array(),
    dinoLeft: Joi.number(),
    dinoRight: Joi.number(),
    IM: Joi.number(),
    IR: Joi.number(),
    heartStatusRes: Joi.array(),
    lpi: Joi.number(),
    pulse: Joi.number(),
    spo2: Joi.number(),
});

export const SurveyResultsForm = ({ checkupId, doctors, initialData }) => {

    initialData.date = formatDateToYMD(initialData.date);

    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});
    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [rateHeart, setRateHeart] = useState([]);
    const [psyTest, setPsyTest] = useState([]);

    const imt = useMemo(() => weight / Math.pow(height / 100, 2), [weight, height]);

    if (saved) {
        return 'Сохранено';
    }

    useEffect(() => {
        api.get('chdeseases/heart-deseases')
            .json()
            .then(list => {
                setRateHeart(list);
            });
        api.get('risks/3')
            .json()
            .then(list => {
                setPsyTest(list);
            });
    }, [])

    const handleSubmit = async (data, { reset }) => {
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });
            const resp = await api.post(`measurements/${checkupId}/43`, { json: result }).json();
            console.log(resp);
            setSaved(true);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit} initialData={initialData}>
            <Field label="Производил" horizontal>
                <Field>
                    <Select name="person">
                        {doctors.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.fullName}
                            </option>
                        ))}
                    </Select>
                </Field>
            </Field>
            <Field label="Дата" horizontal>
                <Field error={errors.date}>
                    <Input type="date" name="date" />
                </Field>
            </Field>  
            <Field horizontal label="Рост">
                <Field error={errors.height}>
                    <Input name="height" onInput={ev => setHeight(ev.target.value)} />
                </Field>
            </Field>
            <Field horizontal label="Вес">
                <Field error={errors.weight}>
                    <Input name="weight" onInput={ev => setWeight(ev.target.value)} />
                </Field>
            </Field>
            <Field horizontal label="ИМТ">
                <Field error={errors.imt} caption="N < 25">
                    <Input name="imt" disabled value={imt || null} />
                </Field>
            </Field>
            <Field horizontal label="Артериальное давление">
                <Field error={errors.bloodPressure} caption="N < 140/90 мм. рт. ст.">
                    <Input name="bloodPressure"/>
                </Field>
            </Field>
            <Field label="Холестерин" horizontal>
                <Field caption="N ≤ 5.0 ммоль/л" error={errors.lipoprotein}>
                    <Input type="number" name="lipoprotein" step="0.01" />
                </Field>
            </Field>
            <Field label="Глюкоза" horizontal>
                <Field caption="N ≤ 5.5 натощак ≤ 7.6 после еды ммоль/л" error={errors.glucose}>
                    <Input type="number" name="glucose" step="0.01" />
                </Field>
            </Field> 
            <Field label="Спирометрия" horizontal>
                <Field>
                    <Field label = "ФЖЕЛ" horizontal>
                        <Input name = "FGEL" type="number"></Input>
                        <p>(</p>
                        <Input name = "FGELBR" type="number"></Input>
                        <p>)</p>
                    </Field>         
                    <Field label = "ОФВ1" horizontal>
                        <Input name = "OFV1" type="number"></Input>
                        <p>(</p>
                        <Input name = "OFV1BR" type="number"></Input>
                        <p>)</p>
                    </Field>
                </Field>
                <Field>
                    <Field label = "ОФВ1/ЖЕЛ" horizontal>
                        <Input name = "OFVFGEL" type="number"></Input>
                        <p>(</p>
                        <Input name = "OFVFGELBR" type="number"></Input>
                        <p>)</p>
                    </Field>
                    <Field label = "СОНВ" horizontal>
                        <Input name = "COHB" type="number"></Input>
                        <p>(</p>
                        <Input name = "COHBBR" type="number"></Input>
                        <p>)</p>
                    </Field>
                </Field>
            </Field>
            <Field>
                <Field horizontal label="Психотест">
                    <Field>
                        <TagsInput
                            name="psytest"
                            onSearch={psyTest}
                            defaultItems={psyTest}
                            fontSz={'x-small'}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                    </Field>
                </Field>
                <Field horizontal label="Динамометрия">
                    <Input name="dinoLeft" placeholder="Левая рука" type="number"></Input>
                    <Input name="dinoRight" placeholder="Правая рука" type="number"></Input>
                </Field>
                <Field label="Экспресс-оценка состояния сердца">
                    <Field horizontal>
                        <Input name="IM" placeholder="ИМ" type="number"></Input>
                        <Input name="IR" placeholder="ИР" type="number"></Input>
                    </Field>
                    <Field horizontal>
                        <TagsInput
                            name="heartStatusRes"
                            onSearch={() => rateHeart}
                            defaultItems={rateHeart}
                            itemToTag={item => item.name}
                            itemToLabel={item => `${item.name}`}
                            itemToId={item => item.id}
                        />
                    </Field>
                </Field>
                <Field label="Ангиологический скрининг" horizontal>
                    <Field>
                        <Input name="lpi" placeholder="ЛПИ" type="number"></Input>
                    </Field>
                    <Field label = "Пульсоксиметрия" horizontal>
                        <Input name="pulse" placeholder="Пульс" type="number"></Input>
                        <Input name="spo2" placeholder="SPO2" type="number"></Input>
                    </Field>
                </Field>
            </Field>
            <Field horizontal>
                <Button fullwidth color="info">
                    Сохранить
                </Button>
            </Field>
        </Form>
    );
}

SurveyResultsForm.defaultProps = {
    initialData: {
        date: todayDate(),
        heartStatusRes: [],
        psytest: [],
    }
}