import { h, Fragment } from 'preact';
import { useState, useRef } from 'preact/hooks';
import Joi, { ref } from 'joi';

import { Checkbox, Field, Input, Radio, RadioGroup, Button, Autocomplete } from '../components';
import { formatPhone, formatPolis, formatPassport, api } from '../helpers';
import { useRifm } from '../rifm';
import Form from '../unform';
import dayjs from 'dayjs';

const schema = Joi.object({
    date: Joi.date(),
    fullName: Joi.string(),
    dateOfBirth: Joi.date(),
    sex: Joi.string().valid('М', 'Ж'),
    phone: Joi.string().allow(''),
    polis: Joi.string().allow(''),
    passport: Joi.string().allow(''),
    address: Joi.string().allow(''),
    room: Joi.string().allow(''),
    university: Joi.string().allow(''),
    education: Joi.string().allow(''),
    job: Joi.string().allow(''),
    position: Joi.string().allow(''),
    application: Joi.number(),
    applicationDate: Joi.date(),
    socialStatus: Joi.number(),
    faculty: Joi.string().allow(''),
    groupNo: Joi.string().allow(''),
    isAttached: Joi.boolean(),
    hasAgreement: Joi.boolean(),
    hadCovid: Joi.boolean(),
    attachedNum: Joi.number(),
    liveIn: Joi.number(),
    directed: Joi.string().allow(''),
});

export const CreatePatientForm = () => {
    const formRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [polis, setPolis] = useState('');
    const [passport, setPassport] = useState('');
    const [CHLess1Year, setCHLess1Year] = useState(null);
    const [currentPatient, setCurrentPatient] = useState(null);

    const phoneRifm = useRifm({
        value: phone,
        onChange: setPhone,
        format: formatPhone,
    });

    const polisRifm = useRifm({
        value: polis,
        onChange: setPolis,
        format: formatPolis,
    });

    const passportRifm = useRifm({
        value: passport,
        onChange: setPassport,
        format: formatPassport,
    });

    const handleSubmit = async (data, { reset }) => {
        console.log(data);
        setLoading(true);
        setErrors({});
        try {
            const result = await schema.validateAsync(data, { abortEarly: false });

            const resp = await api.post('patients', { json: 
                currentPatient? {
                    ...result,
                    autoCompleted: true,
                    patientId: currentPatient.id,
                    prevCheckupDate: CHLess1Year,
                }: result });
            console.log(resp);
            setCurrentPatient(null);
            setCHLess1Year(null);
            reset();
        } catch (err) {
            console.error(err);

            const validationErrors = {};
            if (err instanceof Joi.ValidationError) {
                err.details.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                console.log(validationErrors);
                setErrors(validationErrors);
            }
            else 
            {
                setErrors({globalError: "Не удалось добавить пациента, возможно такого адреса не существует"})
            }
        }
        setLoading(false);
    };

    const searchAddress = async str => {
        const items = await api.get('addresses', { searchParams: { query: str } }).json();
        return items;
    };

    const onAddressClick = addr => {
        formRef.current.setFieldValue('address', `${addr.street} ${addr.building}`);
    };

    const searchPatient = async str => {
        if(str.length >= 2) {
            const items = await api.get('patients/search', {searchParams: {name: str}}).json();
            console.log(items);
            return items;
        }
        return [];
    }

    const onPatientClick = async pat => {
        formRef.current.setFieldValue('fullName', pat.fullName);
        const res = await api.get(`patients/${pat.id}`).json();
        setCurrentPatient(res);
        const lastCheckup = res.checkups.length > 0? res.checkups[0]: null;
        console.log(res);
        if(lastCheckup) {
            const lastCheckupDate = dayjs(lastCheckup.startDate);
            setCHLess1Year(lastCheckupDate.format('YYYY-MM-DD')); 
        }
        formRef.current.setFieldValue('dateOfBirth', dayjs(res.dateOfBirth).format('YYYY-MM-DD'));
        formRef.current.setFieldValue('sex', res.sex);
        formRef.current.setFieldValue('address', res.address);
        formRef.current.setFieldValue('room', res.room);
        setPhone(res.phone);
        setPassport(res.passport);
        setPolis(res.polis);

        if(lastCheckup) {
            lastCheckup.attachedNum && formRef.current.setFieldValue('attachedNum', lastCheckup.attachedNum);
            lastCheckup.socialStatus && formRef.current.setFieldValue('socialStatus', lastCheckup.socialStatus);
            lastCheckup.education && formRef.current.setFieldValue('education', lastCheckup.education);
            lastCheckup.applicationDate && formRef.current.setFieldValue('applicationDate', lastCheckup.applicationDate);
            lastCheckup.job && formRef.current.setFieldValue('job', lastCheckup.job);
            lastCheckup.position && formRef.current.setFieldValue('position', lastCheckup.position);
        }
    }

    const Reset = () => {
        formRef.current.reset();
        setCurrentPatient(null);
        setCHLess1Year(null);
    }

    return (
        <Form
            ref={formRef}
            initialData={{
                date: dayjs().format('YYYY-MM-DD'),
                sex: 'М',
                liveIn: 1,
                socialStatus: 4,
                application: 1,
                applicationDate: dayjs().format('YYYY-MM-DD'),
            }}
            onSubmit={handleSubmit}
        >
            <Field horizontal label="Дата заполнения" required>
                <Input name="date" type="date" />
            </Field>
            <Field horizontal label="ФИО" required>
                <Field error={errors.fullName}>
                    <div className="field has-addons">
                        <Autocomplete
                            name="fullName"
                            placeholder="Иванов Иван Иванович"
                            onSearch={searchPatient}
                            onChange={onPatientClick}
                            disabled={currentPatient?true:false}
                        >
                            {({ item }) => `${item.fullName}`}
                        </Autocomplete>
                    </div>
                    <Field>
                    {currentPatient && <>
                        <Button onClick = {() => Reset()}>
                            Сброс автозаполнения
                        </Button>
                    </>}
                </Field>
                </Field>
            </Field>
            <Field horizontal label="ДР" required>
                <Field error={errors.dateOfBirth}>
                    <Input name="dateOfBirth" type="date" />
                </Field>
            </Field>
            <Field horizontal label="Пол" required>
                <Field error={errors.sex}>
                    <RadioGroup name="sex">
                        <Radio value="М"> Мужской</Radio>
                        <Radio value="Ж"> Женский</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Адрес">
                <div className="field has-addons">
                    <Autocomplete
                        name="address"
                        placeholder="улица, дом"
                        onSearch={searchAddress}
                        onChange={onAddressClick}
                    >
                        {({ item }) => `${item.street}, ${item.building}`}
                    </Autocomplete>
                    <p className="control">
                        <Field error={errors.room}>
                            <Input name="room" placeholder="кв" />
                        </Field>
                    </p>
                </div>
            </Field>
            <Field horizontal label="Живет пост." error={errors.liveIn}>
                <RadioGroup name = "liveIn">
                    <Radio value={1}>Город</Radio>
                    <Radio value={2}>Село</Radio>
                </RadioGroup>
            </Field>
            <Field horizontal label="Полис" required>
                <Field error={errors.polis}>
                    <Input
                        name="polis"
                        value={polisRifm.value}
                        onInput={polisRifm.onChange}
                        placeholder="0000 0000 0000 0000"
                    />
                </Field>
            </Field>
            <Field horizontal label="Телефон">
                <Field error={errors.phone}>
                    <Input
                        name="phone"
                        value={phoneRifm.value}
                        onInput={phoneRifm.onChange}
                        placeholder="7(123)456-78-90"
                    />
                </Field>
            </Field>
            <Field horizontal label="Паспорт">
                <Field error={errors.passport}>
                    <Input
                        name="passport"
                        value={passportRifm.value}
                        onInput={passportRifm.onChange}
                        placeholder="12 34 567890"
                    />
                </Field>
            </Field>
            <Field horizontal label="Соц. положение">
                <RadioGroup name = "socialStatus">
                    <Radio value={1}>служащий</Radio>
                    <Radio value={2}>рабочий</Radio>
                    <Radio value={3}>учащийся</Radio>
                    <Radio value={4}>неработающий</Radio>
                </RadioGroup>
            </Field>
            <Field horizontal label="Образование">
                <Input name="education"></Input>
            </Field>
            <Field horizontal label="Место работы">
                <Input name="job"></Input>
            </Field>
            <Field horizontal label="Профессия, должность">
                <Input name="position"></Input>
            </Field>
            <Field horizontal label="Категория обращения">
                <Field>
                    <RadioGroup name = "application">
                        <Radio value = {1}>Обратился самостоятельно</Radio>
                        <Radio value = {2}>Направлен ЛПУ по месту прикрепления</Radio>
                        <Radio value = {3}>Направлен из стационаров после острого заболевания</Radio>
                        <Radio value = {4}>Направлен врачём, ответственным за проведение доп. диспансеризации работающих граждан с I и II группами состояние здоровья</Radio>
                        <Radio value = {5}>Направлен работодателем по заключению врача, ответственного за проведение периодических мед. осмотров</Radio>
                    </RadioGroup>
                </Field>
            </Field>
            <Field horizontal label="Дата обращения" required>
                <Field error={errors.applicationDate}>
                    <Input name="applicationDate" type="date"></Input>
                </Field>
            </Field>
            {CHLess1Year && dayjs().diff(dayjs(CHLess1Year), 'years') <= 1 && <>
                
                <span style={{color:"red"}}>⚠ Данный пациент уже был в базе центра здоровья менее чем год назад!</span>
            </>}       
            <Field horizontal>
                <Button fullwidth color="primary" loading={loading}>
                    Создать
                </Button>
            </Field>
            <Field error={errors.globalError}/>
        </Form>
    );
};
