import { h } from 'preact';
import clsx from 'clsx';

import { Element } from './Element';

export const ButtonGroup = ({ size, align, hasAddons, ...props }) => (
    <Element
        className={clsx('buttons', {
            'has-addons': hasAddons,
            [`is-${align}`]: align,
            [`are-${size}`]: size,
        })}
        {...props}
    />
);
